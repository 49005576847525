<template>
    <div>
        <div class="search-container">
            <vs-row>
                <vs-col vs-w="5" vs-type="flex" vs-justify="flex-start" style="padding: 0px">
                    <div class="eligibility_count">
                        <p class="eligibility_text">Enrollment</p>
                        <p class="eligibility_text">{{ countsleads }}</p>
                    </div>
                </vs-col>
            </vs-row>
            <vs-row>
                <vs-col vs-w="8.9" vs-offset="0.1" class="mt-4">
                    <vs-row class="my-6">
                        <vs-col vs-w="1" vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding">
                            <p class="filter-heading">Filters</p>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2.5"
                            class="visit-tree-drop none_padding" id="teams">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns spoc-class ele-inside-text alignment py-1" href.prevent
                                    @click="showTreeBox">
                                    Team
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect :value-consists-of="'LEAF_PRIORITY'" :multiple="true" :options="team_list"
                                        v-model="selected_team" placeholder="select..." />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2.5"
                            class="visit-tree-drop none_padding" id="citys">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns spoc-class ele-inside-text alignment py-1" href.prevent
                                    @click="showTreeBox">
                                    City
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect :value-consists-of="'LEAF_PRIORITY'" :multiple="true" value-format="object"
                                        :options="city_list" placeholder="Select Spoc" v-model="selected_city" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2.5"
                            class="visit-tree-drop none_padding" id="cc">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns spoc-class ele-inside-text alignment py-1" href.prevent
                                    @click="showTreeBox">
                                    City Classification
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="initSelectedCC" :value-consists-of="'LEAF_PRIORITY'"
                                        :multiple="true" :options="city_classification_list" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2.5"
                            class="visit-tree-drop none_padding" id="spoc">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns spoc-class ele-inside-text py-1" href.prevent
                                    @click="showTreeBox">
                                    Enrolled By SPOC
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="initSelectedspoc" :multiple="true" :options="spoc_list"
                                        :value-consists-of="'LEAF_PRIORITY'" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2.5" vs-offset="1"
                            class="visit-tree-drop none_padding mt-6" id="sc">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns ele-inside-text spoc-class alignment py-1" href.prevent
                                    @click="showTreeBox">
                                    SPOC Classification
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="initSelectedSpocCC" :multiple="true" :options="spoc_cc_list" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2.5"
                            class="visit-tree-drop none_padding mt-6" id="course">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns spoc-class ele-inside-text py-1" href.prevent
                                    @click="showTreeBox">
                                    Course
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="initSelectedCourses" :multiple="true" :options="treeDataCourses" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2.5"
                            class="visit-tree-drop none_padding mt-6" id="level_drop">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns spoc-class ele-inside-text py-1" href.prevent
                                    @click="showTreeBox">
                                    Levels
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="initSelectedLevels" :multiple="true" :options="treeDataLevels" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2.5"
                            class="visit-tree-drop none_padding mt-6" id="macc_level_drop">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconns spoc-class ele-inside-text py-1" href.prevent
                                    @click="showTreeBox">
                                    USP Levels
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="init_selected_iiml_levels" :multiple="true" :options="macc_levels" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                    </vs-row>
                    <!-- <vs-row>
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6"
                                vs-w="1.5">
                                <p class="filter-heading">Date Filter</p>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6"
                                vs-w="2.5">
                                <div style="display: inline-grid; width: 100%; margin-top: -13px">
                                    <label class="date_filter_heading">Next Call</label>
                                    <datepicker ref="picker" class="visit_date_picker" :opens="'right'" :locale-data="{
                                        firstDay: 1,
                                        format: 'DD-MMM-YYYY',
                                    }" placeholder="DD/MM/YY" :minDate="null" :maxDate="null"
                                        :singleDatePicker="false" :timePicker="false" :timePicker24Hour="true"
                                        :showWeekNumbers="false" :showDropdowns="false" :autoApply="false"
                                        v-model="nextCallVisit"></datepicker>
                                </div>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6"
                                vs-offset="0.5" vs-w="2.5">
                                <div style="display: inline-grid; width: 100%; margin-top: -13px">
                                    <label class="date_filter_heading">Last Call</label>
                                    <datepicker ref="picker" class="visit_date_picker" :opens="'right'" :locale-data="{
                                        firstDay: 1,
                                        format: 'DD-MMM-YYYY',
                                    }" placeholder="DD/MM/YY" :minDate="null" :maxDate="null"
                                        :singleDatePicker="false" :timePicker="false" :timePicker24Hour="true"
                                        :showWeekNumbers="false" :showDropdowns="false" :autoApply="false"
                                        v-model="lastCallVisit"></datepicker>
                                </div>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6"
                                vs-offset="0.5" vs-w="2.5">
                                <div style="display: inline-grid; width: 100%; margin-top: -13px">
                                    <label class="date_filter_heading">Visit month and year</label>
                                    <datepicker class="visit_date_picker" placeholder="MM/YY" :minimumView="'month'"
                                        :maximumView="'month'" :format="customFormatter" v-model="dates_visit">
                                    </datepicker>
                                </div>
                            </vs-col>
                        </vs-row> -->
                    <vs-row class="my-12">
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" vs-offset="1.1"
                            class="visit-tree-drop none_padding" id="status">
                            <treeselect v-model="selected_ef_status" :options="ef_status_list" placeholder="EF Status" />
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" vs-offset="0.3"
                            class="visit-tree-drop none_padding" id="status">
                            <treeselect v-model="initSelectedPayment" :options="payments_list" placeholder="Payment" />
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" vs-offset="0.3"
                            class="visit-tree-drop none_padding" id="status">
                            <vs-input v-model="filter_amount" placeholder="Amount" :disabled="payment_empty">
                            </vs-input>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" vs-offset="0.3"
                            class="visit-tree-drop none_padding" id="status">
                            <treeselect v-model="selected_tenure" :options="tenure_list" placeholder="Loan" />

                        </vs-col>
                    </vs-row>
                    <vs-row>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="end" vs-w="1">
                            <p class="filter-heading">Date Filter</p>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="end"
                            class="none_padding enrollment_date_range" vs-offset="0.2" vs-w="3">
                            <div>
                                <label style="margin-top:2px;">Enrollment Date Range</label>
                                <date-range-picker ref="picker" class="visit_date_picker2" :opens="'right'" :locale-data="{
                                    firstDay: 1,
                                    format: 'DD-MMM-YYYY',
                                }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                                    :timePicker24Hour="true" :showWeekNumbers="false" :showDropdowns="false"
                                    :autoApply="false" v-model="enrollmentDateRange"
                                    style="width: 100%"></date-range-picker>
                            </div>
                        </vs-col>
                        <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="end" class="none_padding"
                            vs-offset="0.1" vs-w="3">
                            <div>
                                <label  style="margin-top:2px;">Enrollment Date Range</label>
                                <date-range-picker ref="picker" class="visit_date_picker2" :opens="'right'"
                                    :locale-data="{
                                        firstDay: 1,
                                        format: 'DD-MMM-YYYY',
                                    }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                                    :timePicker24Hour="true" :showWeekNumbers="false" :showDropdowns="false"
                                    :autoApply="false" v-model="enrollmentDateRange"
                                    style="width: 100%"></date-range-picker>
                            </div>
                        </vs-col> -->
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="enrollment_date_range"
                            vs-offset="0.2" vs-w="4">
                            <div>
                                <label style="margin-top:2px;">Enrollment Month & Year</label>
                                <datepicker class="visit_date_picker" placeholder="MM/YY" :minimumView="'month'"
                                    :maximumView="'month'" :format="customFormatter" v-model="dates_enrlmt">
                                </datepicker>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-offset="0.1" vs-w="0.7"
                            style="margin-top: 15px">
                            <div>
                                <vs-button color="#0044ba" icon="clear" size="small" @click="clearDateFilter"></vs-button>
                            </div>
                        </vs-col>
                    </vs-row>

                    <vs-row class=" mt-6 mb-2">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" class="none_padding" vs-w="12">

                            <div class="visitor_search_criteria mt-3">
                                <h6 class="Criteria_heading ml-2 mt-2">Search Criteria:</h6>
                                <vs-row vs-w="12" class="mt-2">
                                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-offset="0.1"
                                        vs-w="10.7">
                                        <div style="display: inline-block; width: 100%;">
                                            <span v-for="chip in teamData" :key="chip">
                                                <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                                    @click="removechip(chip, 'team')" color="#CAECC5">
                                                    <b v-if="chip == 'ExEd'">USP</b>
                                                    <b v-else>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-for="chip in cityData" :key="chip">
                                                <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                                    @click="removechip(chip, 'city')" color="#EAE5FA">
                                                    <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-for="chip in ccData" :key="chip">
                                                <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                                    @click="removechip(chip, 'cc')" color="#FAE1FE">
                                                    <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-for="chip in spocData" :key="chip">
                                                <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                                    @click="removechip(chip, 'spoc')" color="#D0F1Fb">
                                                    <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-for="chip in spocCcData" :key="chip">
                                                <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                                    @click="removechip(chip, 'spoccc')" color="#B4EDFF">
                                                    <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-for="chip in courseData" :key="chip">
                                                <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                                                    @click="removechip(chip, 'course')" color="#FFD5D5">
                                                    <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-for="chip in selected_levels" :key="chip">
                                                <vs-chip v-if="chip !== 'divider'" @click="removechip(chip, 'level')" class="criteria_chip"
                                                closable color="#f5e1ce">
                                                <b>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                            <span v-for="chip in selected_iiml_levels" :key="chip">
                                                <vs-chip v-if="chip !== 'divider'" @click="removechip(chip, 'iiml_level')" class="criteria_chip"
                                                closable color="#edebf3">
                                                <b v-if="chip == 'blank'">No U-Level</b>
                                                <b v-else>{{ chip }}</b>
                                                </vs-chip>
                                            </span>
                                        </div>
                                    </vs-col>
                                    <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="1.2">
                                        <vs-button color="#0044ba" icon="search" @click="analyzePagination(1)"
                                            size="small"></vs-button>
                                        &nbsp;
                                        <vs-button color="#0044ba" icon="refresh" @click="refreshVisitorData()"
                                            size="small"></vs-button>
                                    </vs-col>
                                </vs-row>
                            </div>
                        </vs-col>
                    </vs-row>
                </vs-col>
                <vs-col vs-w="3" vs-type="flex" vs-justify="center" vs-align="flex-start" style="margin-top: -1.5%">
                    <vx-card class="enrollment-options_filter">
                        <p class="enrollment-option-side mb-4">Options</p>
                        <vs-row class="mb-4">
                            <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                                <vs-input placeholder="Can-ID" v-model="can_id" class="enrollment-input-filter" />
                                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF"
                                    type="gradient" @click="analyzePagination(1)" icon="search"></vs-button>
                            </vs-col>
                        </vs-row>
                        <vs-row class="mb-4">
                            <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                                <vs-input placeholder="Candidate Name" v-model="value1" class="enrollment-input-filter" />
                                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF"
                                    type="gradient" icon="search" @click="analyzePagination(1)"></vs-button>
                            </vs-col>
                        </vs-row>
                        <vs-row class="mb-4">
                            <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                                <vs-input placeholder="Company" v-model="company" class="enrollment-input-filter" />
                                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF"
                                    type="gradient" icon="search" @click="analyzePagination(1)"></vs-button>
                            </vs-col>
                        </vs-row>
                        <vs-row class="mb-4">
                            <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                                <vs-input placeholder="Contact Number" v-model="candidate_number"
                                    class="enrollment-input-filter" />
                                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF"
                                    type="gradient" icon="search" @click="analyzePagination(1)"></vs-button>
                            </vs-col>
                        </vs-row>
                        <vs-row class="mb-3">
                            <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                                <vs-input placeholder="Email Address" v-model="email" class="enrollment-input-filter" />
                                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF"
                                    type="gradient" icon="search" @click="analyzePagination(1)"></vs-button>
                            </vs-col>
                        </vs-row>
                        <vs-row class="mb-3">
                            <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                                <vs-input placeholder="Batch" v-model="selected_batch" class="enrollment-input-filter" />
                                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF"
                                    type="gradient" icon="search" @click="analyzePagination(1)"></vs-button>
                            </vs-col>
                        </vs-row>
                        <vs-row>
                            <vs-col vs-type="flex" vs-justify="center" class="mb-1">
                                <vs-button color="#0044BA" icon="search" size="small"
                                    @click="analyzePagination(1)">Search</vs-button>
                            </vs-col>
                        </vs-row>
                    </vx-card>
                </vs-col>
            </vs-row>
        </div>
        <div class="mt-3" v-show="table_data_showing">
            <enrollments-tabulator :enrollments_table_details="enrollments_table_details" @data-transfer="analyzePagination"
                @download-enrollments-report="downloadReport()" />
            <div v-if="enrollments_table_details.length == 0" style="color: grey; margin: 5px">
                <center>NO DATA AVAILABLE</center>
            </div>
        </div>
        <vs-row style="margin-top: 3%">
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
                <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
            </vs-col>
        </vs-row>
        <vs-popup class="holamundo download-report" title="" :active.sync="download_report_success_popup">
            <div class="popup-box">
                <h5>Download Success <vs-icon icon="schedule_send" size="22px" color="#91C11E"
                        style="margin-left:12px"></vs-icon></h5>
                <p>Enrollments Report will be sent to your mail shortly</p>
                <!-- <span>x</span> -->
            </div>
        </vs-popup>
    </div>
</template>

<script>
import datatable from "../components/pagesComponents/EnrollmentDatatable.vue";
import axios from "axios";
import constants from "../../constants.json";
import "vue-tree-halower/dist/halower-tree.min.css";
import Treeselect from "@riophae/vue-treeselect";
// import _ from "lodash";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
import "vue-tree-halower/dist/halower-tree.min.css";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import EventBus from "../components/eventbus.js";
import { VTree, VSelectTree } from "vue-tree-halower";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
import moment from 'moment';
import EnrollmentsTabulator from './EnrollmentsTabulator.vue';
export default {
    components: {
        datatable,
        Treeselect,
        Datepicker,
        VTree,
        VSelectTree,
        DateRangePicker,
        "v-select": vSelect,
        VuePerfectScrollbar,
        'enrollments-tabulator': EnrollmentsTabulator
    },
    data() {
        return {
            all_spocs_data: [],
            payment_empty: true,
            currentTablePage: 1,
            tablelinks: 0,
            table_data_showing: false,
            enrollments_table_details: [],
            initSelectedPayment: null,
            initSelectedSpocCC: [],
            download_report_success_popup: false,
            payments_list: [{ id: 'agreed_cost', label: 'Agreed Cost' }, { id: 'amount_paid', label: 'Amount Paid' }, { id: 'amount_due', label: 'Amount Due' }],
            candidate_number: "",
            email: "",
            company: "",
            filter_amount: "",
            nextCallVisit: "",
            lastCallVisit: "",
            nextCallEnrlmt: {
                startDate: "",
                endDate: ""
            },
            lastCallEnrlmt: {
                startDate: "",
                endDate: ""
            },
            enrollmentDateRange: {
                startDate: null,
                endDate: null
            },
            dates_visit: null,
            dates_enrlmt: null,
            cpa_cma_filter: false,
            selected_loan_status: "",
            selected_tenure: null,
            selected_ef_status: null,
            selected_batch: "",
            tenure_list: [{ id: 0, label: 'No' }, { id: 1, label: 'Yes' }],
            ef_status_list: [{ id: 'Completed', label: 'Completed' }, { id: 'InComplete', label: 'In-Complete' }],
            cities_list: [],
            selected_city: [],
            ccf_spoc_ids: [],
            selected_team: [],
            team_list: [{
                id: "All",
                label: "All",
                children: [{ id: "CM", label: "CM" }, { id: "SR", label: "SR" },{ id: "GM", label: "GM" }]
            }],
            city_list: [
                {
                    id: "All",
                    label: "All",
                    children: []
                }
            ],
            city_classification_list: [
                {
                    id: "All",
                    label: "All",
                    children: []
                }
            ],
            spoc_list: [
                {
                    id: "All",
                    label: "All",
                    children: []
                }
            ],
            spoc_cc_list: [
                {
                    id: "All",
                    label: "All",
                    children: []
                }
            ],
            initSelectedCC: [],
            initSelectedspoc: [],
            selected_frm_part: [],
            frm_parts_list: ["Part 1", "Part 2"],
            selected_cfa_level: [],
            cfa_levels_list: ["Level 1", "Level 2", "Level 3"],
            countsleads: 0,
            selectedCity: [],
            dateRange: {
                startDate: "",
                endDate: "",
            },
            agreementStatus: ["Complete", "Incomplete"],
            enrollment_date: {
                startDate: "",
                endDate: "",
            },
            searchCriteria: [],
            usercity: "",
            name: "",
            searchIn: {
                id: "",
                name: "",
            },
            can_id: "",
            rawBde: [],
            rawBdeSR: [],
            value1: "",
            course: "",
            courses: [],
            batch: "",
            email_status: "",
            mwb_names: [],
            SpocCityPrefilled: [],
            SpocPrefilled: "",
            initSelectedBDE: [],
            initSelectedBDESR: [],
            initSelectedCourses: [],
            enrollmentstotalCount: "0",
            treeDataLevels: [
                {
                id: "All",
                label: "All",
                children: [
                    {
                    id: "P1",
                    label: "P1",
                    children: [
                        {
                            id: "M3",
                            label: "M3",
                        },
                        {
                        id: "M3+",
                        label: "M3+",
                        },
                        {
                        id: "M3++",
                        label: "M3++",
                        },
                        {
                        id: "M5",
                        label: "M5",
                        },
                        {
                        id: "M6",
                        label: "M6",
                        },
                    ],
                    },
                    {
                    id: "P2",
                    label: "P2",
                    children: [
                        {
                        id: "M4",
                        label: "M4",
                        },
                        {
                        id: "M2",
                        label: "M2",
                        },
                        // {
                        //   id: "L4",
                        //   label: "L4",
                        // },
                        // {
                        //   id: "L6",
                        //   label: "L6",
                        // },
                        // {
                        //   id: "L5",
                        //   label: "L5",
                        // },
                        // {
                        //   id: "L3+",
                        //   label: "L3+",
                        // },
                    ],
                    },
                    {
                    id: "P3",
                    label: "P3",
                    children: [
                        {
                        id: "M4-",
                        label: "M4-",
                        },
                        {
                        id: "M1",
                        label: "M1",
                        },
                        // {
                        //   id: "L4-",
                        //   label: "L4-",
                        // },
                        // {
                        //   id: "L1",
                        //   label: "L1",
                        // },
                    ],
                    },
                    {
                    id: "ENROLLED",
                    label: "ENROLLED",
                    children: [
                {
                    id: "M7",
                    label: "M7",
                },
                {
                    id: "M7-",
                    label: "M7-",
                },
                {
                    id: "M7+",
                    label: "M7+",
                },
                {
                    id: "M7#",
                    label: "M7#",
                },
                {
                    id: "M7-JJ",
                    label: "M7-JJ",
                },
                {
                    id: "M7X",
                    label: "M7X",
                },
                {
                    id: "M7D1",
                    label: "M7D1",
                },
                {
                    id: "M7D2",
                    label: "M7D2",
                },
                {
                    id: "M8#1",
                    label: "M8#1"
                },
                {
                    id: "M8#1-",
                    label: "M8#1-"
                },
                {
                    id: "M8#1+",
                    label: "M8#1+"
                },
                {
                    id: "M8#2",
                    label: "M8#2"
                },
                {
                    id: "M8",
                    label: "M8"
                },
                {
                    id: "M8-",
                    label: "M8-",
                },
                {
                    id: "M8+",
                    label: "M8+"
                },
                {
                    id: "M8+1",
                    label: "M8+1"
                },
                {
                    id: "M8+2",
                    label: "M8+2"
                },
                {
                    id: "M8+3",
                    label: "M8+3"
                },
                {
                    id: "M8+4",
                    label: "M8+4"
                },
                {
                    id: "M8+5",
                    label: "M8+5"
                },
                {
                    id: "M8+6",
                    label: "M8+6"
                },
                {
                    id: "M9",
                    label: "M9",
                },
                {
                    id: "M9+1",
                    label: "M9+1",
                },
                {
                    id: "M9+2",
                    label: "M9+2",
                },
                {
                    id: "M9+3",
                    label: "M9+3",
                },
                {
                    id: "M9-",
                    label: "M9-",
                },
                {
                    id: "M10",
                    label: "M10",
                },
                {
                    id: "M10#",
                    label: "M10#",
                },
                {
                    id: "M11",
                    label: "M11",
                },
                {
                    id: "M11#",
                    label: "M11#",
                },
                {
                    id: "M12",
                    label: "M12",
                },
                {
                    id: "M7-IR",
                    label: "M7-IR",
                },
                {
                    id: "M7-INT",
                    label: "M7-INT",
                }
              ]
                    },
                    {
                    id: "Dnd",
                    label: "Dnd",
                    children: [
                        {
                        id: "N/A",
                        label: "N/A",
                        },
                        {
                        id: "DND",
                        label: "DND",
                        },
                    ]
                    }
                ],
                },
            ],
            macc_levels: [
                {
                id: "All",
                label: "All",
                children: [
                    { id: "blank", label: "No U Level" },
                    { id: "U0--", label: "U0--" },
                    { id: "U0", label: "U0" },
                    { id: "U1", label: "U1" },
                    { id: "U1+", label: "U1+" },
                    { id: "U2", label: "U2" },
                    { id: "U3-", label: "U3-" },
                    { id: "U3", label: "U3" },
                    { id: "U3+", label: "U3+" },
                    { id: "U3++", label: "U3++"},
                    { id: "U4", label: "U4" },
                    { id: "U5", label: "U5" },
                    { id: "U6", label: "U6" },
                    { id: "U7-", label: "U7-" },
                    { id: "U7", label: "U7" },
                    { id: "U7R", label: "U7R" },
                    { id: "U7+", label: "U7+" },
                    { id: "U8", label: "U8" },
                    { id: "U8+", label: "U8+" },
                    { id: "U9-", label: "U9-" },
                    { id: "U9", label: "U9" },
                    { id: "U9+", label: "U9+" },
                    { id: "U10-", label: "U10-" },
                    { id: "U10", label: "U10" },
                    { id: "U11", label: "U11" },
                    { id: "U11+", label: "U11+" },
                    { id: "U12", label: "U12" },
                ]
                }
            ],
            treeDataBDE: [
                {
                    id: "All",
                    label: "All",
                    children: [],
                },
            ],
            treeDataBDESR: [
                {
                    id: "All",
                    label: "All",
                    children: [],
                },
            ],
            treeDataCourses: [
                {
                    id: "All",
                    label: "All",
                    children: [
                        {
                            id: "CPA",
                            label: "CPA",
                        },
                        {
                            id: "CPA+USP",
                            label: "CPA+USP",
                        },
                        {
                            id: "CPA-AA",
                            label: "CPA-AA"
                        },
                        {
                            id: "USP",
                            label: "USP"
                        },
                        {
                            id: "Bridge Course – Public Accounting",
                            label: "Bridge Course – Public Accounting"
                        },
                        // {
                        //   id: "AICPA",
                        //   label: "AICPA",
                        // },
                        // {
                        //   id: "CISA",
                        //   label: "CISA",
                        // },
                        // {
                        //   id: "EA",
                        //   label: "EA",
                        // },
                        // {
                        //   id: "CIA",
                        //   label: "CIA",
                        // },
                        {
                            id: "CMA",
                            label: "CMA",
                        },
                        {
                            id: "CFA",
                            label: "CFA",
                        },
                        {
                            id: "FRM",
                            label: "FRM",
                        },
                        // {
                        //     id: "MAcc",
                        //     label: "MAcc",
                        // },
                        // {
                        //   id: "RPO",
                        //   label: "RPO"
                        // },
                        // {
                        //   id: "IIML-FA",
                        //   label: "IIML-FA",
                        // },
                        // {
                        //   id: "IIML-SF",
                        //   label: "IIML-SF",
                        // },
                        // {
                        //     id: "IIML-FT",
                        //     label: "IIML-FT",
                        // },
                        // {
                        //   id: "IIML-DA",
                        //   label: "IIML-DA",
                        // },
                        // {
                        //   id: "IIML-HR",
                        //   label: "IIML-HR",
                        // },
                        // {
                        //   id: "IITR-BF",
                        //   label: "IITR-BF",
                        // },
                        // {
                        //   id: "IITR-DB",
                        //   label: "IITR-DB",
                        // },
                        // {
                        //   id: "IITM-AA",
                        //   label: "IITM-AA",
                        // },
                        // {
                        //   id: "IIMK-CX",
                        //   label: "IIMK-CX",
                        // },
                        // { id: "IITM-FS", label: "IITM-FS" },
                        // { id: "IITR-CC", label: "IITR-CC" },
                        // { id: "IIMK-FT", label: "IIMK-FT" },
                        // { id: "IIML-AB", label: "IIML-AB" },
                        // { id: "IIML-SH", label: "IIML-SH" },
                        // { id: "IITJ-DE", label: "IITJ-DE" },
                        // { id: "XLRI-HR", label: "XLRI-HR" },
                        // { id: "XLRI-SH", label: "XLRI-SH" },
                        // { id: "IIMI-BA", label: "IIMI-BA" },
                        // { id: "IIMI-AA", label: "IIMI-AA" },
                        // { id: "XLRI-DM", label: "XLRI-DM" },
                        // {
                        //   id: "IIML-PM",
                        //   label: "IIML-PM",
                        // },
                        // {
                        //   id: "MAYBE",
                        //   label: "MAYBE"
                        // }
                    ],
                },
            ],
            teamData: [],
            cityData: [],
            ccData: [],
            spocData: [],
            spoc_ids: [],
            spocCcData: [],
            courseData: [],
            selected_spoc_ids: [],
            initSelectedLevels: [],
            init_selected_iiml_levels: [],
            selected_levels: [],
            selected_iiml_levels: [],
            logged_in_user_id:"",
        };
    },
    mounted() {
        // this.refreshVisitorData();
        this.logged_in_user_id =  localStorage.getItem("spoc_id");
        this.selected_team = []
        this.analyzePagination(1)
        this.getAllSpocs();
    },
    watch: {
        dates_enrlmt(val) {
            if (val != null) {
                this.enrollmentDateRange = {
                    startDate: null,
                    endDate: null
                }
            }
        },
        enrollmentDateRange(val) {
            if (val.startDate != null && val.endDate != null) {
                this.dates_enrlmt = null;
            }
        },
        filter_amount(val) {
            if (isNaN(val) || val.indexOf(' ') >= 0) {
                this.$vs.notify({
                    title: "Enter a Number",
                    text: "Amount field must be a number",
                    color: "danger",
                });
                setTimeout(() => {
                    this.filter_amount = "";
                }, 1000)

            } if (val.length > 6) {
                this.filter_amount = val.substring(0, val.length - 1);
                this.$vs.notify({
                    text: "Amount must be 6-digits or less",
                    color: "danger",
                });
            }
        },
        selected_team(val) {
            this.initSelectedLevels = [];
            this.init_selected_iiml_levels = [];
            this.selected_city = []
            this.teamData = []
            this.cityData = []
            this.ccData = []
            this.spocData = []
            this.spoc_ids = []
            this.city_list[0].children = [],
                this.city_classification_list[0].children = [],
                this.spoc_cc_list[0].children = [],
                this.spoc_list[0].children = [],
                this.spocCcData = [];
            if ((val.includes('CM') || val.includes('ExEd')) && val.includes('SR')) {
                this.getSpocAdmin()
            } else if (val.includes('CM') || val.includes('ExEd')) {
                this.getSpocAdmin()
            } else if (val == 'SR') {
                this.getSRSpocs();
            }
            if (val != undefined || val != []) {
                this.mergeAllSearch()
            }
            //&& this.city_list[0].children.length == 0 && this.city_classification_list[0].children.length == 0 && this.spoc_cc_list[0].children.length == 0 && this.spoc_list[0].children.length == 0
            if (this.initSelectedCC.length == 0 && this.selected_city.length == 0 && this.initSelectedspoc.length == 0 && this.initSelectedSpocCC.length == 0) {
                this.getAllSpocsDataClassification();
            }
        },
        selected_city(val) {
            let rawBde = this.rawBde
            if (this.selected_team == 'SR') {
                rawBde = this.rawBdeSR
            }
            this.city_classification_list = [
                {
                    id: "All",
                    label: "All",
                    children: []
                }
            ]
            this.spoc_list[0].children = []
            this.initSelectedCC = []
            this.initSelectedspoc = []
            rawBde.forEach(element => {
                val.forEach(city => {
                    if (element.city == city.label && this.selected_team != 'ExEd') {
                        if (!this.city_classification_list[0].children.find(item => item.label === element.city_classification)) { //to remove city classification duplicates
                            if (element.city_classification != null && element.city_classification != 'X') {
                                var obj = {
                                    id: element.city_classification, // x is in more than one city
                                    label: element.city_classification
                                };
                                this.city_classification_list[0].children.push(obj);
                            } else if (element.city_classification != null && this.selected_team.includes('ExEd')) {
                                var obj0 = {
                                    id: element.city_classification, // x is in more than one city
                                    label: element.city_classification
                                };
                                this.city_classification_list[0].children.push(obj0);
                            }
                        }
                    } else if (element.city == city.label && this.selected_team == 'ExEd') {
                        this.city_classification_list[0].children = []
                        var obj1 = {
                            id: 'X', // x is in more than one city
                            label: 'X'
                        };
                        this.city_classification_list[0].children.push(obj1);
                    }
                });
            });
            // if (this.city_classification_list[0].children.length == 0) {
            //     this.city_classification_list = []
            // }
            this.ccf_spoc_ids = [];
            val.forEach(element => {
                this.ccf_spoc_ids.push(element.id);
            });
            this.mergeAllSearch()
            this.ccData = []
            this.spocData = [];
            this.spoc_ids = [];
            if (this.initSelectedCC.length == 0 && this.selected_city.length == 0 && this.initSelectedspoc.length == 0 && this.initSelectedSpocCC.length == 0) {
                this.getAllSpocsDataClassification();
            }
        },
        initSelectedCC(val) {

            let rawBde = this.rawBde
            if (this.selected_team.includes('SR') && this.selected_team.includes('CM')) {
                this.rawBdeSR.forEach(obj => {
                    rawBde.push(obj)
                });
            } else if (this.selected_team.includes('SR') && this.selected_team.includes('ExEd')) {
                rawBde = this.rawBdeSR
                this.rawBde.forEach(obj => {
                    if (obj.city_classification == 'X') {
                        rawBde.push(obj)
                    }
                });
            } else if (!this.selected_team.includes('CM') && !this.selected_team.includes('ExEd')) {
                rawBde = this.rawBdeSR
            } else if (!this.selected_team.includes('SR')) {
                rawBde = this.rawBde
            }
            this.spoc_list = [{
                id: "All",
                label: "All",
                children: []
            }]
            this.initSelectedspoc = []
            let duplicates = []
            rawBde.forEach(element => {
                this.selected_city.forEach(cc => {
                    if (!duplicates.includes(element.full_name) && element.city == cc.label) {
                        val.forEach(ccf => {
                            if (element.city_classification == ccf) {
                                if (this.selected_team == 'ExEd' && ccf == 'X') {
                                    var obj1 = {
                                        id: element.id,
                                        label: element.full_name,
                                    };
                                    this.spoc_list[0].children.push(obj1);
                                    duplicates.push(element.full_name)
                                    return
                                } else if (!this.selected_team.includes('ExEd') && this.selected_team.includes('CM')) {
                                    if (ccf != 'X') {
                                        var obj2 = {
                                            id: element.id,
                                            label: element.full_name,
                                        };
                                        this.spoc_list[0].children.push(obj2);
                                        duplicates.push(element.full_name)
                                        return
                                    }
                                } else if (!this.selected_team.includes('CM')) {
                                    if (ccf == 'X' && this.selected_team.includes('ExEd')) {
                                        var obj3 = {
                                            id: element.id,
                                            label: element.full_name,
                                        };
                                        this.spoc_list[0].children.push(obj3);
                                        duplicates.push(element.full_name)
                                    } if (this.selected_team.includes('SR') && ccf != 'X') {
                                        obj3 = {
                                            id: element.id,
                                            label: element.full_name,
                                        };
                                        this.spoc_list[0].children.push(obj3);
                                        duplicates.push(element.full_name)
                                    }
                                } if (this.selected_team.includes('ExEd') && this.selected_team.includes('CM')) {
                                    var obj = {
                                        id: element.id,
                                        label: element.full_name,
                                    };
                                    this.spoc_list[0].children.push(obj);
                                    duplicates.push(element.full_name)
                                }
                            }
                        });
                    }
                });
            });
            // if (this.spoc_list[0].children.length == 0) {
            //     this.spoc_list = []
            // }
            this.mergeAllSearch()
            this.spocData = [];
            this.spoc_ids = [];
            if (this.initSelectedCC.length == 0 && this.selected_city.length == 0 && this.initSelectedspoc.length == 0 && this.initSelectedSpocCC.length == 0) {
                this.getAllSpocsDataClassification();
            }
        },
        initSelectedspoc: function () {
            if (this.initSelectedCC.length == 0 && this.selected_city.length == 0 && this.initSelectedspoc.length == 0 && this.initSelectedSpocCC.length == 0) {
                this.getAllSpocsDataClassification();
            }
            this.mergeAllSearch()
        },
        initSelectedSpocCC: function () {
            if (this.initSelectedCC.length == 0 && this.selected_city.length == 0 && this.initSelectedspoc.length == 0 && this.initSelectedSpocCC.length == 0) {
                this.getAllSpocsDataClassification();
            }
            this.mergeAllSearch()
        },
        initSelectedCourses: function () {
            this.mergeAllSearch()
        },
        initSelectedLevels(val) {
            console.log("initSelectedLevels", val);
            this.mergeAllSearch();
        },
        init_selected_iiml_levels(val) {
            console.log("init_selected_iiml_levels", val);
            this.mergeAllSearch();
        },
        initSelectedPayment(val) {
            if (val != null || val != undefined || val != '') {
                this.payment_empty = false
            } else {
                this.payment_empty = true
            }
        },
        currentTablePage(val) {
            this.analyzePagination(val)
        }
    },
    methods: {
        getAllSpocs() {
            let url = `${constants.SERVER_API}getAllSpocs`;
            axios
                .get(url, {
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                    //   console.log("getAllSpocs",response.data.spocs);
                    this.all_spocs_data = response.data.spocs;
                    this.getAllSpocsDataClassification();

                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        getAllSpocsDataClassification() {

            this.city_list[0].children = [];
            this.city_classification_list[0].children = [];
            this.spoc_cc_list[0].children = [];
            this.spoc_list[0].children = [];
            let all_city_list = [];
            let all_city_cc_list = [];
            let all_spoc_cc_list = [];
            let all_spocs_list = [];


            all_city_list = [...new Set(this.all_spocs_data.map((item) => item.city)),]
            all_city_cc_list = [...new Set(this.all_spocs_data.map((item) => item.city_classification)),]
            all_spoc_cc_list = [...new Set(this.all_spocs_data.map((item) => item.user_tag)),]
            all_spocs_list = [...new Set(this.all_spocs_data.map((item) => item.full_name)),]

            let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
            for (let i = 0; i < all_city_list.length; i++) {
                const element = all_city_list[i];
                if (element != "") {
                    let obj = {
                        id: element,
                        label: element,
                    }
                    if(city_options.includes(element)){
                        this.city_list[0].children.push(obj);
                    }
                }
            }
            this.city_list[0].children = this.sortNestedArrayAlphabetically(this.city_list[0].children);
            for (let i = 0; i < all_city_cc_list.length; i++) {
                const element = all_city_cc_list[i];

                if(this.logged_in_user_id == 132 ||this.logged_in_user_id == 937 ||this.logged_in_user_id == 39 ){
                    if (element != "") {
                        let obj = {
                            id: element,
                            label: element,
                         }
                         this.city_classification_list[0].children.push(obj);
                     }
                }
                else{
                        if(element != 'X'){
                            if (element != "") {
                                let obj = {
                                    id: element,
                                    label: element,
                                }
                                this.city_classification_list[0].children.push(obj);
                            }
                        }
                }
            }
            for (let i = 0; i < all_spoc_cc_list.length; i++) {
                const element = all_spoc_cc_list[i];
                // if (element != "") {
                //     let obj = {
                //         id: element,
                //         label: element,
                //     }
                //     this.spoc_cc_list[0].children.push(obj);
                // }
                if(this.logged_in_user_id == 132 ||this.logged_in_user_id == 937 ||this.logged_in_user_id == 39){
                    if (element != "") {
                        let obj = {
                            id: element,
                            label: element,
                         }
                         this.spoc_cc_list[0].children.push(obj);
                     }
                }
                else{
                        if(element != 'X'){
                            if (element != "") {
                                let obj = {
                                    id: element,
                                    label: element,
                                }
                                this.spoc_cc_list[0].children.push(obj);
                            }
                        }
                }
            }
            for (let i = 0; i < all_spocs_list.length; i++) {
                const element = all_spocs_list[i];
                // if (element != "") {
                //     let obj = {
                //         id: element,
                //         label: element,
                //     }
                //     this.spoc_list[0].children.push(obj);
                // }
                if(this.logged_in_user_id == 132 ||this.logged_in_user_id == 937 ||this.logged_in_user_id == 39 ){
                    if (element != "") {
                        let obj = {
                            id: element,
                            label: element,
                         }
                         this.spoc_list[0].children.push(obj);
                     }
                }
                else{
                        if(element.substring(0,1) != 'X'){
                            if (element != "") {
                                let obj = {
                                    id: element,
                                    label: element,
                                }
                                this.spoc_list[0].children.push(obj);
                            }
                        }
                }
            }
        },
        datatoTimestamp(ms) {
            return moment(ms).format("DD-MM-YYYY")
        },
        timetoTimestamp(incoming) {
            return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
        },
        showTreeBox() {
            return this.treeDataBDE;
        },
        analyzePagination(page) {
            if (this.selected_team != undefined || this.selected_team != []) {
                this.getEnrollments(page);
            } else {
                this.$vs.notify({
                    title: "Team Field Is Required",
                    text: "field is required",
                    color: "danger",
                });
            }
        },
        getAgreementStatus(agreed_status) {
            if (agreed_status == 'InComplete') {
                return 0;
            } else { return 1; }
        },
        getAllSpocsIds() {
            this.selected_spoc_ids = [];
            this.all_spocs_data.forEach((spoc_data) => {
                this.spoc_ids.forEach((selected_spoc) => {
                    if (spoc_data.full_name == selected_spoc) {
                        this.selected_spoc_ids.push(spoc_data.id);
                    }
                })
            })
        },
        getEnrollments(page) {
            this.$vs.loading();
            var year = null;
            var month = null;
            this.selected_spoc_ids = [];
            console.log("selected spoc Id", this.spoc_ids)
            if (this.spoc_ids.length != 0) {
                this.getAllSpocsIds();
            }
            if (this.dates_enrlmt != null) {
                month = moment(this.dates_enrlmt).format("MM");
                year = moment(this.dates_enrlmt).format("YYYY")
            }
            let cpa_cma_filter = 0;
            if (this.enrollments_table_details.cpa_cma == 1) {
                cpa_cma_filter = 1
            }

            let params = {
                'can_id': this.can_id,
                'name': this.value1,
                'company': this.company,
                'contact_number': this.candidate_number,
                'email': this.email,
                'team': this.selected_team.toString(),
                'city': this.cityData.toString(),
                'city_classification': this.ccData.toString(),
                'spoc_classification': this.spocCcData.toString(),
                'spoc_ids': this.selected_spoc_ids.join(),
                'course': this.courseData.toString(),
                'levels': this.selected_levels.toString(),
                'iiml_levels': this.selected_iiml_levels.toString(),
                'type': this.initSelectedPayment,
                'amount': this.filter_amount,
                'loan': '',
                'batch': this.selected_batch,
                'agreement_status': null,
                // 'next_call_from_date': "",
                // 'next_call_to_date': "",
                // 'last_call_from_date': "",
                // 'last_call_to_date': "",
                'enrollment_data_from_date': "",
                'enrollment_data_to_date': "",
                'month': month,
                'year': year,
                'cpa_cma': cpa_cma_filter,
            };
            // if (this.spoc_ids.length == 0) {
            //     params.team = this.selected_team.toString(),
            //     params.city = this.cityData.toString(),
            //     params.city_classification = this.ccData.toString()
            // }

            // if (this.nextCallEnrlmt.startDate !== "" && this.nextCallEnrlmt.endDate !== "") {
            //     params.next_call_from_date = this.datatoTimestamp(this.nextCallEnrlmt.startDate);
            //     params.next_call_to_date = this.datatoTimestamp(this.nextCallEnrlmt.endDate);
            // }
            // if (this.lastCallEnrlmt.startDate !== "" && this.lastCallEnrlmt.endDate !== "") {
            //     params.last_call_from_date = this.datatoTimestamp(this.lastCallEnrlmt.startDate);
            //     params.last_call_to_date = this.datatoTimestamp(this.lastCallEnrlmt.endDate);
            // }
            // enrollmentDateRange
            if (this.enrollmentDateRange.startDate != null && this.enrollmentDateRange.endDate !== null) {
                params.enrollment_data_from_date = this.datatoTimestamp(this.enrollmentDateRange.startDate);
                params.enrollment_data_to_date = this.datatoTimestamp(this.enrollmentDateRange.endDate);
            }
            if (this.selected_tenure != null) {
                params.loan = this.selected_tenure;
            }
            if (this.selected_ef_status != null || this.selected_ef_status != undefined) {
                params.agreement_status = this.getAgreementStatus(this.selected_ef_status)
            }

            // console.log("params", params);

            axios
                .get(`${constants.SERVER_API}getEnrollmentsDetails?page=${page}`, {
                    params: params,
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    if (response.data.status === "error") {
                        this.handleNotification(response);
                    } else {
                        this.enrollments_table_details = []
                        this.countsleads = response.data.enrollments.total;
                        this.tablelinks = response.data.enrollments.last_page
                        this.currentTablePage = response.data.enrollments.current_page
                        this.table_data_showing = true
                        response.data.enrollments.data.forEach((lead) => {
                            lead.engagements = [];
                            lead.mobile = [];
                            lead.emails = [];
                        });
                        this.enrollments_table_details = response.data.enrollments.data
                        // this.$store.commit("MOUNT_MWB_LEADS", response.data.enrollments.data);
                    }
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.handleError(error);
                    this.$vs.loading.close();
                });
        },
        downloadReport() {
            let year = "";
            let month = "";
            this.selected_spoc_ids = [];
            console.log("selected spoc Id", this.spoc_ids)
            if (this.spoc_ids.length != 0) {
                this.getAllSpocsIds();
            }
            if (this.dates_enrlmt != null) {
                month = moment(this.dates_enrlmt).format("MM");
                year = moment(this.dates_enrlmt).format("YYYY")
            }
            let cpa_cma_filter = 0;
            if (this.enrollments_table_details.cpa_cma == 1) {
                cpa_cma_filter = 1
            }
            let params = {
                'user_email': localStorage.getItem("email_id"),
                'can_id': this.can_id,
                'name': this.value1,
                'company': this.company,
                'contact_number': this.candidate_number,
                'email': this.email,
                'team': this.selected_team.toString(),
                'city': this.cityData.toString(),
                'city_classification': this.ccData.toString(),
                'spoc_classification': this.spocCcData.toString(),
                'spoc_ids': this.selected_spoc_ids.join(),
                'course': this.courseData.toString(),
                'levels': this.selected_levels.toString(),
                'iiml_levels': this.selected_iiml_levels.toString(),
                'type': '',
                'amount': this.filter_amount,
                'loan': '',
                'batch': this.selected_batch,
                'agreement_status': "",
                'enrollment_data_from_date': "",
                'enrollment_data_to_date': "",
                'month': month,
                'year': year,
                'cpa_cma': cpa_cma_filter,
            };
            if (this.enrollmentDateRange.startDate != null && this.enrollmentDateRange.endDate !== null) {
                params.enrollment_data_from_date = this.datatoTimestamp(this.enrollmentDateRange.startDate);
                params.enrollment_data_to_date = this.datatoTimestamp(this.enrollmentDateRange.endDate);
            }
            if (this.selected_tenure != null) {
                params.loan = this.selected_tenure;
            }
            if (this.selected_ef_status != null || this.selected_ef_status != undefined) {
                if (this.selected_ef_status == 'InComplete') {
                    params.agreement_status = 0
                } else {
                    params.agreement_status = 1
                }
            }
            if (this.initSelectedPayment != null || this.initSelectedPayment != undefined) {
                    params.type = this.initSelectedPayment;
            }
            console.log("params", params);
            let url = `${constants.MILES_GOOGLE_INVITE}enrollmentsExportCsv?`;
            axios.get(url, { params: params, headers: { Authorization: `Bearer ${localStorage.userAccessToken}`, }, }).then((response) => {
                this.download_report_success_popup = true;
                console.log("All_Data", response.data);
                EventBus.$emit("close-popup");
                setTimeout(() => this.download_report_success_popup = false, 4000)
            })
                .catch((error) => {
                    if (error.response.status == 403) {
                        this.$vs.notify({
                            text: error.response.data.message,
                            color: "danger",
                        });
                    } else {
                        this.handleError(error);
                    }
                });
        },
        getCanId() {
            if (
                localStorage.getItem("role") === "cm4" ||
                localStorage.getItem("role") === "ac2"
            ) {
                return this.can_id;
            } else {
                if (this.can_id !== "") {
                    let spoc_can_id = `${this.can_id}`;
                    return spoc_can_id;
                } else {
                    return this.can_id;
                }
            }
        },

        clearDateFilter() {
            this.enrollmentDateRange = {
                startDate: null,
                endDate: null
            };
            this.dates_enrlmt = null;
        },
        getSpocAdmin() {
            let url = `${constants.SERVER_API}getSpocs`;
            axios
                .get(url, {
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                    // console.log(response);
                    this.searchIn.id = response.data.logged_in_user_id;
                    this.searchIn.name = response.data.logged_in_user_name;
                    this.rawBde = response.data.spocs;
                    this.treeDataBDE[0].children = [];
                    this.rawcities = [];
                    this.rawBde.forEach((sr) => {
                        this.rawBde.push(sr);
                    })
                    var unique = response.data.spocs
                        .map((name) => {
                            return {
                                count: 1,
                                name: name.city,
                            };
                        })
                        .reduce((a, b) => {
                            a[b.name] = (a[b.name] || 0) + b.count;
                            return a;
                        }, {});
                    for (var key in unique) {
                        if (unique.hasOwnProperty(key)) {
                            this.rawcities.push(key);
                            var obj = {
                                id: key,
                                label: key,
                                children: [],
                            };
                            this.treeDataBDE[0].children.push(obj);
                        }
                    }
                    let spoccc = []
                    this.spoc_cc_list = [{ id: 'All', label: 'All', children: [] }]
                    response.data.spocs.forEach((server_spoc) => {
                        if (!spoccc.includes(server_spoc.user_tag)) {
                            spoccc.push(server_spoc.user_tag);
                            let obj = {
                                id: server_spoc.user_tag,
                                label: server_spoc.user_tag
                            }
                            this.spoc_cc_list[0].children.push(obj)
                        }
                    });
                    this.getCityClassificationSpocs(response.data.spocs);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        getCityClassificationSpocs(spocs_data) {
            this.cities_list = [...new Set(spocs_data.map((item) => item.city)),];
            this.city_list = [
                {
                    id: "All",
                    label: "All",
                    children: []
                }];
            for (let i = 0; i < this.cities_list.length; i++) {
                const element = this.cities_list[i];
                if (element != null) {
                    var obj = {
                        id: element,
                        label: element
                    };
                    this.city_list[0].children.push(obj);
                }
            }

            this.city_list[0].children = this.sortNestedArrayAlphabetically(
                this.city_list[0].children
            );
            if (this.selected_team.includes('SR')) {
                this.getSRSpocs()
            }
        },
        getSRSpocs() {
            let url = `${constants.SERVER_API}getSRSpocs`;
            axios
                .get(url, {
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                    this.searchIn.id = response.data.logged_in_user_id;
                    this.searchIn.name = response.data.logged_in_user_name;
                    this.rawBdeSR = response.data.spocs;
                    let sr = response.data.spocs;
                    let sr_spocs = [];
                    for (let i = 0; i < sr.length; i++) {
                        const element = sr[i];
                        if (element.team == "SR") {
                            sr_spocs.push(element);
                        }
                    }
                    this.rawBdeSR = sr_spocs
                    if (!this.selected_team.includes('CM')) {
                        this.cities_list = [...new Set(sr_spocs.map((item) => item.city)),];
                    }
                    this.city_list = [
                        {
                            id: "All",
                            label: "All",
                            children: []
                        }];
                    for (let i = 0; i < this.cities_list.length; i++) {
                        const element = this.cities_list[i];
                        if (element != null) {
                            var obj = {
                                id: element,
                                label: element
                            };
                            this.city_list[0].children.push(obj);
                        }
                    }

                    this.city_list[0].children = this.sortNestedArrayAlphabetically(
                        this.city_list[0].children
                    );
                    let spoccc = []
                    this.spoc_cc_list = [{ id: 'All', label: 'All', children: [] }]
                    this.rawBdeSR.forEach((server_spoc) => {
                        if (!spoccc.includes(server_spoc.user_tag)) {
                            spoccc.push(server_spoc.user_tag);
                            let obj = {
                                id: server_spoc.user_tag,
                                label: server_spoc.user_tag
                            }
                            this.spoc_cc_list[0].children.push(obj)
                        }
                    });
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        mergeAllSearch() {
            this.teamData = [];
            this.cityData = [];
            this.ccData = [];
            this.spocData = [];
            this.spoc_ids = []
            this.spocCcData = [];
            this.courseData = [];
            if (this.selected_team != undefined || this.selected_team != []) {
                this.selected_team.forEach((child) => {
                    this.teamData.push(child)
                });
            }
            if (this.selected_city.length != 0) {
                if (this.selected_city.length == this.city_list[0].children.length) {
                    this.cityData.push('All')
                } else {
                    this.selected_city.forEach((child) => {
                        this.cityData.push(child.label);
                    });
                }
            }
            if (this.initSelectedCC.length != 0) {
                if (this.initSelectedCC[0] == 'All') {
                    this.ccData.push('All');
                }
                else this.initSelectedCC.forEach((child) => {
                    this.ccData.push(child);
                });
            }
            if (this.initSelectedspoc.length != 0) {
                if (this.initSelectedspoc[0] == 'All') {
                    this.spocData.push('All');
                    this.spoc_ids.push('All')
                } else this.initSelectedspoc.forEach((child) => {
                    this.spoc_list[0].children.forEach((spoc) => {
                        if (spoc.id == child) {
                            this.spocData.push(spoc.label);
                            this.spoc_ids.push(spoc.label)
                        }
                    });
                });
            }
            if (this.initSelectedSpocCC.length != 0) {
                if (this.initSelectedSpocCC[0] == 'All') {
                    this.spocCcData.push('All');
                } else this.initSelectedSpocCC.forEach((cc) => {
                    this.spocCcData.push(cc);
                });
            }
            if (this.initSelectedCourses.length != 0) {
                if (this.initSelectedCourses[0] == 'All') {
                    this.courseData.push('All');
                } else this.initSelectedCourses.forEach((cc) => {
                    this.courseData.push(cc);
                });
            }
            //initSelectedLevels
      this.selected_levels = [];
      console.log("initSelectedLevels array", this.initSelectedLevels);
      this.initSelectedLevels.forEach((sort) => {
        console.log("initSelectedLevels element", sort);
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
          this.treeDataLevels[0].children[3].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
          this.treeDataLevels[0].children[4].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
        } else if (sort === "ENROLLED") {
          this.treeDataLevels[0].children[3].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
        }
        else if (sort === "Dnd") {
          this.treeDataLevels[0].children[4].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
        } else {
          console.log("sort", sort);
          this.selected_levels.push(sort);
        }
        // } else {
        //   this.selected_levels.push(sort);
        // }
      });
      //init_selected_iiml_levels
      this.selected_iiml_levels = [];
      this.init_selected_iiml_levels.forEach((sort) => {
        if (sort === "All") {
          this.macc_levels[0].children.forEach((child) => {
            this.selected_iiml_levels.push(child.label);
          });
        } else {
          this.selected_iiml_levels.push(sort);
        }
      });
        },
        removechip(chip, type) {
            if (type == 'team') {
                this.selected_team.splice(this.selected_team.indexOf(chip), 1);
                this.teamData.splice(this.teamData.indexOf(chip), 1);
            } else if (type == 'city') {
                if (chip == 'All') {
                    this.selected_city = []
                    this.cityData = []
                } else {
                    this.selected_city.splice(this.selected_city.indexOf(chip), 1);
                    this.cityData.splice(this.cityData.indexOf(chip), 1);
                }
            } else if (type == 'cc') {
                if (chip == 'All') {
                    this.initSelectedCC = []
                    this.ccData = []
                } else {
                    this.initSelectedCC.splice(this.initSelectedCC.indexOf(chip), 1);
                    this.ccData.splice(this.ccData.indexOf(chip), 1);
                }
            } else if (type == 'spoc') {
                if (chip == 'All') {
                    this.initSelectedspoc = []
                    this.spocData = []
                } else {
                    this.initSelectedspoc.splice(this.initSelectedspoc.indexOf(chip), 1);
                    this.spocData.splice(this.spocData.indexOf(chip), 1);
                }
            } else if (type == 'spoccc') {
                if (chip == 'All') {
                    this.initSelectedSpocCC = []
                    this.spocCcData = []
                } else {
                    this.initSelectedSpocCC.splice(this.initSelectedSpocCC.indexOf(chip), 1);
                    this.spocCcData.splice(this.spocCcData.indexOf(chip), 1);
                }
            } else if (type == 'course') {
                if (chip == 'All') {
                    this.initSelectedCourses = []
                    this.courseData = []
                } else {
                    this.initSelectedCourses.splice(this.initSelectedCourses.indexOf(chip), 1);
                    this.courseData.splice(this.courseData.indexOf(chip), 1);
                }
            }
            if (type == "level") {
                // this.initSelectedLevels.splice(this.initSelectedLevels.indexOf(chip), 1);
                if (this.initSelectedLevels.indexOf("All") == 0) {
                this.initSelectedLevels = [];
                this.treeDataLevels[0].children.forEach((child) => {
                    child.children.forEach((chi) => {
                    if (chi.label !== chip) {
                        this.initSelectedLevels.push(chi.label);
                        this.selected_levels.push(chi.label);
                    }
                    });
                });
                } else if (this.initSelectedLevels.indexOf(chip) !== -1) {
                this.initSelectedLevels.splice(this.initSelectedLevels.indexOf(chip), 1);
                this.selected_levels.splice(this.initSelectedLevels.indexOf(chip), 1);
                } else {
                let childProperty = "";
                this.treeDataLevels[0].children.forEach((child) => {
                    if ("children" in child) {
                    child.children.forEach((chi) => {
                        if (chi.label === chip) {
                        childProperty = child.label;
                        }
                    });
                    }
                });
                this.initSelectedLevels.splice(
                    this.initSelectedLevels.indexOf(childProperty),
                    1
                );
                this.selected_levels.splice(
                    this.initSelectedLevels.indexOf(childProperty),
                    1);
                this.treeDataLevels[0].children.forEach((child) => {
                    if (child.label === childProperty) {
                    if ("children" in child) {
                        child.children.forEach((chi) => {
                        if (chi.label !== chip) {
                            this.initSelectedLevels.push(chi.label);
                            this.selected_levels.push(chi.label);
                        }
                        });
                    }
                    }
                });
                }
            }
            if (type == 'iiml_level') {
                this.selected_iiml_levels = [];
                if (this.init_selected_iiml_levels.indexOf(chip) === -1) {
                this.init_selected_iiml_levels = [];
                this.macc_levels[0].children.forEach((child) => {
                    if (child.label !== chip) {
                    this.init_selected_iiml_levels.push(child.label);
                    this.selected_iiml_levels.push(child.label);
                    }
                });
                } else {
                this.init_selected_iiml_levels.splice(this.init_selected_iiml_levels.indexOf(chip), 1);
                this.selected_iiml_levels.splice(
                    this.selected_iiml_levels.indexOf(chip),
                    1
                );
                }
            }
        },
        customFormatter(date) {
            return moment(date).format("MMM-YYYY");
        },
        refreshVisitorData() {
            this.selected_ef_status = null,
            this.selected_team = [];
            this.selected_tenure = null,
            this.filter_amount = "",
            this.initSelectedPayment = null,
            this.initSelectedCourses = [];
            this.initSelectedLevels = [];
            this.init_selected_iiml_levels = [];
            this.initSelectedSpocCC = [];
            this.teamData = [];
            this.spocCcData = [];
            this.courseData = [];
            this.selected_spoc_ids = [];
            this.cityData = [];
            this.ccData = [];
            this.spocCcData = [];
            this.can_id = '',
            this.candidate_number = "",
            this.selected_batch = "",
            this.value1 = "",
            this.company = "",
            this.email = "",
            this.selected_spoc_ids = [];
            this.spoc_ids = [];
            // this.nextCallEnrlmt={
            //     startDate: "",
            //     endDate: ""
            // },
            // this.lastCallEnrlmt= {
            //     startDate: "",
            //     endDate: ""
            // },
            this.enrollmentDateRange = {
                startDate: null,
                endDate: null
            }
            this.dates_enrlmt = null
            this.analyzePagination(1)
        }
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/pages/dropdown.scss";

.headr {
    color: gray;
    font-size: 25px;
    margin-bottom: 2%;
}

.gap {
    margin-bottom: 4%;
}

.inicolor {
    color: gray;
}

.cardtext {
    color: gray;
    font-size: 20px;
}

.tree3 {
    padding: 10px;
    box-sizing: border-box;
    border: 1px dotted #ccccdd;
    overflow: auto;
    height: 500px;
    width: 300px;
}

.vs-dropdown--menu {
    width: 300px;
}

.gap {
    margin-right: 1%;
}

.examplex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.vl {
    border-left: 1px solid black;
    height: 35px;
}

.search-container {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    width: 100%;
    min-height: 450.69px;
}

.eligibility_count {
    background: #0044ba;
    border-radius: 15px 0px;
    width: 233.62px;
    height: 37.8px;
    display: flex;
    justify-content: space-between;
    padding-inline: 1%;
    align-items: center;
}

.eligibility_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
}

.none_padding {
    padding: 0px;
}

.no-padding {
    padding: 0px;
}

.filter-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
}

.visit-tree-drop#teams>.vs-con-dropdown {
    background: #CAECC5;
    border: 1px solid #8ACC81;
    width: 90%;
    border-radius: 10px;
    margin-inline: 2%;
}


.visit-tree-drop#citys>.vs-con-dropdown {
    background: #eae5fa;
    border: 1px solid rgba(46, 13, 146, 0.5);
    width: 90%;
    border-radius: 10px;
    margin-inline: 2%;
}

.visit-tree-drop#cc>.vs-con-dropdown {
    background: #fae1fe;
    border: 1px solid #de56f4;
    border-radius: 10px;
    width: 90%;
    margin-inline: 2%;
}

.visit-tree-drop#spoc>.vs-con-dropdown {
    background: rgba(188, 235, 250, 0.69);
    border: 1px solid #1fabd8;
    border-radius: 10px;
    width: 90%;
    margin-inline: 2%;
}

.visit-tree-drop#sc>.vs-con-dropdown {
    background: #b4edff;
    border: 1px solid #13a0cd;
    border-radius: 10px;
    width: 90%;
    margin-inline: 2%;
}

.visit-tree-drop#course>.vs-con-dropdown {
    background: rgba(255, 194, 194, 0.69);
    border: 1px solid #ec6868;
    border-radius: 10px;
    width: 90%;
    margin-inline: 2%;
}

.visit-tree-drop#Visitor>.vs-con-dropdown {
    background: rgba(255, 194, 194, 0.69);
    border: 1px solid #ec6868;
    border-radius: 10px;
    width: 100%;
    line-height: 35px;
}

.visit-tree-drop#level>.vs-con-dropdown {
    background: rgba(255, 194, 194, 0.69);
    border: 1px solid #ec6868;
    border-radius: 10px;
    width: 100%;
    line-height: 35px;
}

.visit-tree-drop#status>.vs-con-dropdown {
    background: #FFFFFF;
    border: 1px solid #5681CB;
    border-radius: 10px;
    width: 100%;
}
.visit-tree-drop#level_drop>.vs-con-dropdown {
  background: #f5e1ce;
  border: 1px solid #e8a76f;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}
.visit-tree-drop#macc_level_drop>.vs-con-dropdown {
  background: #edebf3;
  border: 1px solid #2e0d92;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}

.visit-tree-drop#type>.vs-con-dropdown {
    background: #f1ad78;
    border: 1px solid #F38630;
    border-radius: 10px;
    width: 100%;
}

.visit-tree-drop>.vs-con-dropdown {
    width: 100%;
    min-height: 32px;
    border-radius: 10px;
    // padding: 5.8px;
    padding-inline: 5px;
    // padding-top:3px;
    color: #000000;
    display: flex !important;
    align-items: center;
}

.visit-tree-drop>div>.vue-treeselect__control {
    border: 1px solid #0044BA !important;
    border-radius: 10px;
    height: 43px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000;
}

.visit-tree-drop>.vs-input>.vs-con-input {
    border-radius: 10px;

    .vs-inputx {
        border: 1px solid #0044BA !important;
        border-radius: 10px;
        height: 43px;
    }

    span {
        display: flex;
        align-content: center;
    }
}

.a-iconns>.material-icons {
    margin-left: auto;
}

.a-icon {
    outline: none;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

i {
    font-size: 18px;
}

.a-iconns {
    color: #000;
    font-weight: 500;
    font-size: 14px;
    width: 99%;
    display: flex;
    justify-content: flex-end !important;
}

.alignment {
    text-align: initial;
}

.ele-inside-text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    padding-inline-start: 10px;
}

.ele-inside-text>i {
    color: rgba(0, 0, 0, 0.4);
}

.date_filter_heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

.visitor_dropdown .vs__dropdown-toggle {
    height: 45px !important;
}

.visitor_dropdown .vs__dropdown-toggle .vs__selected-options span {
    font-weight: 500;
    font-size: 14px;
    font-family: "Montserrat";
    font-style: normal;
    color: #000000;
}

.visitor>.reportrange-text {
    background: #ffffff;
    border: 1px solid #0044ba;
    box-shadow: 0px 4px 5px rgb(0 0 0 / 16%);
    min-height: 44.47px;
    border-radius: 10px;
}

.visitor_search_criteria {
    width: 100%;
    background: #ffffff;
    min-height: 165px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.Criteria_heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}

.enrollment-option-side {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.enrollment-input-filter {
    width: 100% !important;
}

.enrollment-input-filter.vs-con-input .input-span-placeholder {
    font-size: 14px;
    margin-left: 1%;
}

.enrollment-input-filter>.vs-con-input>.vs-inputx {
    height: 40px;
    border: 1px solid #0044ba !important;
    border-radius: 15px;
}

.criteria_chip {
    border-radius: 5px;
}

.criteria_chip .vs-chip--close {
    background: transparent !important;
    color: #000000;
}

.criteria_chip .vs-chip--text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    color: #000000;
}

.enrollment-options_filter {
    min-height: 402.41px;
    margin: 2px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.enrollment-options_filter .vx-card__collapsible-content .vx-card__body {
  padding: 5px 20px !important;
}

.labell {
    z-index: 1000;
    position: inherit;
    width: 200px;
    // left: 27%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
    padding-inline-start: 10px;
}

.daterangee>.reportrange-text {
    display: inline-flex;
    border: 1px solid #0044ba;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    height: 44px;
    width: 100%;
}

.daterangee>.reportrange-text>span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}

.visit_date_picker>div input {
    background: #ffffff;
    -webkit-padding-start: 9%;
    display: block;
    border-radius: 5px;
    min-height: 44.47px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    width: 100%;
    border: 1px solid #0044ba;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
    color: #000000 !important;
    border-radius: 10px;
}

.visit_date_picker2>div {
    background: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    border: 0.5px solid #0044ba;
    color: #000000 !important;
    border-radius: 10px;
    display: flex;
    align-content: center;
    align-items: center;
    min-height: 40px;
}

.visit_date_picker2 {
    border: 1px solid #0044BA;
    border-radius: 10px;
}

.batch-select {
    width: 300px;
    z-index: 35000;
}

.batch-select>div {
    border-radius: 10px;
    border: 1px solid #0044ba !important;
}

@media screen and (max-width: 1600px) {
    .spoc-class {
        font-size: 9px !important;
    }
}

@media screen and (max-width: 1400px) {
    .spoc-class {
        font-size: 8px !important;
    }

    .labell {
        font-size: 10px;
    }

    .daterangee>.reportrange-text {
        width: 81%;
    }
}

@media screen and (min-width: 1850px) and (max-width: 1950px) {
    .enrollment_date_range {
        width: 19% !important;
    }
}
</style>
