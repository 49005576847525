<template>
    <div>
        <vx-card class="tabulator_card">
            <vs-row class="mb-3">
                <vs-col vs-w="3" vs-type="flex" vs-justify="flex-start" class="visit-tree-drop">
                    <vs-dropdown vs-custom-content vs-trigger-click>
                        <a class="a-icon a-iconns ele-inside-text alignment py-1" href.prevent @click="showTreeBox">
                            Hide Coloumn
                            <i class="material-icons">expand_more</i>
                        </a>
                        <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                            <treeselect v-model="selected_column" openDirection="bottom" :multiple="true"
                                :options="treeDataHide" placeholder="Hide Column" />
                        </vs-dropdown-menu>
                    </vs-dropdown>
                </vs-col>
                <vs-col vs-w="3" vs-type="flex" vs-justify="flex-start" vs-align="center">
                    <vs-button color="#0044BA" size="small" @click="HideColumn">
                        Hide - Unhide Columns
                    </vs-button>
                </vs-col>
                <vs-col vs-w="3" vs-offset="3" vs-type="flex" vs-justify="flex-end" vs-align="center">
                    <vs-button color="#0044BA" icon="file_download" size="small" @click="downloadCvsTable"
                        ref="loadableButton" id="button-with-loading" class="vs-con-loading__container"
                        v-if="show_btn">Download</vs-button>
                </vs-col>
            </vs-row>
            <vs-row style="margin-top: 2%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2" class="mb-6">
                    <b>Search Criteria:</b>
                </vs-col>
                <vs-col style="overflow-y: scroll" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9"
                    class="mb-6">
                    <div v-for="(chip, index) in searchCriteria" :key="index">
                        <vs-chip v-if="chip !== 'divider'" closable @click="removechip(chip)" color="#0044BA">
                            <b>{{ getChipData(chip) }}</b>
                        </vs-chip>
                        <div v-else>
                            <div class="vl"></div>
                        </div>
                    </div>
                </vs-col>
            </vs-row>
            <div ref="table2" id="example-table-theme"></div>
        </vx-card>
        <vs-popup :id="'assignlead'" :title="'Transfer Enrollments'" :active.sync="transfer_enrollment_popup">
            <vs-row style="margin-top: 5%">
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <b style="color: gray">City</b>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="10">
                    <v-select :options="cities" v-model="cityselected" class="v-selectt"
                        style="width: 450px; position: fixed; z-index: 1000" />
                </vs-col>
            </vs-row>
            <vs-row style="margin-top: 5%">
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <b style="color: gray">SPOC</b>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="10">
                    <v-select label="full_name" :multiple="false" :options="cityspoc" v-model="value1" class="v-selectt"
                        style="width: 450px; position: fixed; z-index: 900" />
                </vs-col>
            </vs-row>
            <vs-row style="margin-top: 3%">
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="12">
                    <vs-button color="#0044BA" type="filled" @click="transferEnrollmentToSPoc()">Assign</vs-button>
                </vs-col>
            </vs-row>
        </vs-popup>
        <vs-popup :title="`${batch_popup_title}`" :active.sync="update_batch_popup">
            <vs-row>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
                    Batch Date :
                    <datepicker placeholder="Select Batch Date" class="visit_date_picker" v-model="batch_date"
                        style="position: fixed; width: 350px"></datepicker>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
                    <vs-button color="#0044BA" type="filled" size="small" @click="updateBatchDate">Update Batch
                        Date</vs-button>
                </vs-col>
            </vs-row>
            <br />
        </vs-popup>
    </div>
</template>

<script>
import { TabulatorFull as Tabulator } from "tabulator-tables";
import Treeselect from "@riophae/vue-treeselect";
import moment from "moment";
import axios from "axios";
import constants from "../../constants.json";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import eventBus from '../components/eventbus';

export default {
    components: {
        Treeselect,
        vSelect,
        Datepicker
    },
    props: ["enrollments_table_details"],
    created() {
        this.getVisitorDetails();
        eventBus.$on("close-popup", () => {
            this.$vs.loading.close(this.$refs.loadableButton.$el)
        });
    },
    mounted() {
        this.hideDownload();
        this.getVisitorDetails();
        for (let i = 0; i < this.enrollments_table_details.length; i++) {
            const element = this.enrollments_table_details[i];
            if (element.enrollment_date !== null) {
                element.enrollment_date = moment.unix(element.enrollment_date).format("DD-MMM-YYYY");
            }
            if (element.agreed_status == 0) {
                element.agreed_status = 'Incomplete';
            } else if (element.agreed_status == 1) { element.agreed_status = 'Complete' }

            if (element.agreement.sent_status !== 0) {
                element.agreement.sent_status = 'Sent';
            } else element.agreement.sent_status = 'old Data';


            Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
            Object.assign(element, { mail: "https://icon-library.com/images/1358030.png" });
            Object.assign(element, { trans: "https://icon-library.com/images/redo-icon/redo-icon-16.jpg" });
            Object.assign(element, { updt: "https://icon-library.com/images/recent-icon/recent-icon-21.jpg" });
        }
    },
    data() {
        return {
            cityspoc: [],
            batch_date: "",
            transfer_enrollment_popup: false,
            update_batch_popup: false,
            batch_popup_title: "",
            batch_can_id: "",
            value1: "",
            cities: [],
            cityselected: "",
            enrollmentData: [],
            spoc_names: [],
            rawBdeSR: [],
            hide_all: false,
            searchCriteria: [],
            visitor_details: [],
            selected_column: [],
            treeDataHide: [
                {
                    id: "All",
                    label: "All",
                    children: [
                        {
                            id: "identity",
                            label: "Candidate Id"
                        },
                        {
                            id: "person_name",
                            label: "Candidate Name"
                        },
                        {
                            id: "course",
                            label: "Course"
                        },
                        {
                            label: "Enrollment Date",
                            id: "enrollment_date",
                        },
                        {
                            label: "Level",
                            id: "level",
                        },
                        {
                            label: "USP Level",
                            id: "iiml_level",
                        },
                        {
                            label: "Enrolled By",
                            id: "assigned_to_name",
                        },
                        {
                            id: "spoc_name",
                            label: "SPOC"
                        },
                        {
                            id: "sr_spoc_name",
                            label: "SR SPOC"
                        },
                        {
                            id: "agreed_cost",
                            label: "Agreed Cost"
                        },
                        {
                            id: "amount_paid_value",
                            label: "Amount Paid"
                        },
                        {
                            id: "amount_pending_value",
                            label: "Amount Due"
                        },
                        {
                            id: "agreed_status",
                            label: "Agreement Status"
                        },
                        {
                            label: "Tenure",
                            id: "tenure",
                        },
                        {
                            label: "Email Sent Status",
                            id: "agreement.sent_status",
                        },
                        {
                            label: "About",
                            id: "info",
                        },
                        {
                            label: "Transfer",
                            id: "trans",
                        },
                        {
                            label: "Update Batch Date",
                            id: "updt",
                        },
                        {
                            label: "Resend Mail",
                            id: "mail",
                        },
                    ],
                },
            ],
            show_btn: false,
        };
    },
    watch: {
        cityselected(value) {
            this.filterspoc(value);
        },
        enrollments_table_details(val) {
            this.visitor_details = val;
            console.log("visitor_value", val);
            this.getVisitorDetails();
            for (let i = 0; i < this.visitor_details.length; i++) {
                const element = this.visitor_details[i];

                if (element.enrollment_date !== null) {
                    element.enrollment_date = moment.unix(element.enrollment_date).format("DD-MMM-YYYY");
                }
                if (element.agreed_status == 0) {
                    element.agreed_status = 'Incomplete';
                } else if (element.agreed_status == 1) { element.agreed_status = 'Complete' }

                if (element.agreement.sent_status !== 0) {
                    element.agreement.sent_status = 'Sent';
                } else element.agreement.sent_status = 'old Data';

                Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
                Object.assign(element, { mail: "https://icon-library.com/images/1358030.png" });
                Object.assign(element, { trans: "https://icon-library.com/images/redo-icon/redo-icon-16.jpg" });
                Object.assign(element, { updt: "https://icon-library.com/images/recent-icon/recent-icon-21.jpg" });
            }
        },
        selected_column(val) {
            this.searchCriteria = [];
            this.searchCriteria.push("divider");
            if (val == 'All') {
                this.searchCriteria.push('All')
            } else if (val.length != 0 && val != 'All') {
                for (let k = 0; k < val.length; k++) {
                    this.searchCriteria.push(val[k])
                }
            }
        }
    },
    methods: {
        hideDownload() {
            const id = localStorage.getItem('user_id');
            if (id == 39 || id == 132 || id == 937 || id == 122) {
                this.show_btn = true
            }
        },
        filterspoc(val) {
            this.value1 = "";
            this.cityspoc = [];
            console.log(val);
            this.spoc_names.forEach((spoc) => {
                // console.log(spoc)
                if (spoc.city === val) {
                    this.cityspoc.push(spoc);
                    // console.log("spoc name", spoc.full_name);
                }
            });
        },
        getVisitorDetails() {
            this.tabulator = new Tabulator(this.$refs.table2, {
                maxHeight: "80vh",
                data: this.visitor_details,
                rowFormatter: (function(row) {
                var evenColor = "#F5F6FA";
                var oddColor = "#FFF";
                row.getElement().style.backgroundColor = row._row.position % 2 === 0 ? evenColor : oddColor;
                }),
                columns: [
                    {
                        title: "Candidate Id",
                        field: "identity",
                        headerSort: false,
                        frozen: true
                    },
                    {
                        title: "Candidate Name",
                        field: "person_name",
                        headerSort: false,
                        frozen: true
                    },
                    {
                        title: "Course",
                        field: "course",
                    },
                    {
                        title: "Enrollment Date",
                        field: "enrollment_date",
                        headerSort: false,
                    },
                    {
                        title: "Level",
                        field: "level",
                        headerSort: false,
                    },
                    {
                        title: "USP Level",
                        field: "iiml_level",
                        headerSort: false,
                    },
                    {
                        title: "Enrolled By",
                        field: "assigned_to_name",
                        headerSort: false,
                    },
                    {
                        title: "SPOC",
                        field: "spoc_name",
                        headerSort: false,
                    },
                    {
                        title: "SR SPOC",
                        field: "sr_spoc_name",
                        headerSort: false,
                    },
                    {
                        title: "Agreed Cost",
                        field: "agreed_cost",
                    },
                    {
                        title: "Amount Paid",
                        field: "amount_paid_value",
                    },
                    {
                        title: "Amount Due",
                        field: "amount_pending_value",
                        headerSort: false,
                    },
                    {
                        title: "Agreement Status",
                        field: "agreed_status",
                    },
                    {
                        title: "Loan Tenure",
                        field: "tenure",
                    },
                    {
                        title: "Email Sent Status",
                        field: "agreement.sent_status",
                    },
                    
                    {
                        title: "Resend Mail",
                        field: "mail",
                        formatter: "image",
                        hozAlign: "center",
                        formatterParams: {
                            height: "25px",
                            width: "25px",
                        },
                        cellClick: this.getMail,
                    },
                    {
                        title: "Transfer",
                        field: "trans",
                        headerSort: false,
                        formatter: "image",
                        hozAlign: "center",
                        formatterParams: {
                            height: "25px",
                            width: "25px",
                        },
                        cellClick: this.TransferEnrollment,
                    },
                    {
                        title: "Update Batch Date",
                        field: "updt",
                        headerSort: false,
                        formatter: "image",
                        hozAlign: "center",
                        formatterParams: {
                            height: "25px",
                            width: "25px",
                        },
                        cellClick: this.openUpdateBatchPopup,
                    },
                    {
                        title: "About",
                        field: "info",
                        headerSort: false,
                        frozen: true,
                        formatter: "image",
                        hozAlign: "center",
                        formatterParams: {
                            height: "25px",
                            width: "25px",
                        },
                        cellClick: this.getId,
                    },
                ]
            });
        },
        getMail(e, cell) {
            let id = cell._cell.row.data.agreement
            if (id != null && id != "") {
                eventBus.$emit("open-resend-email", cell._cell.row.data);
            } else {
                this.$vs.notify({
                    title: 'Null Value',
                    text: 'Agreement Can not be empty',
                    color: 'dark,'
                });
            }
        },
        getId(e, cell) {
            let id = cell._cell.row.data.mwb_id
            console.log("okkkk", e, id);
            if (id != null && id != "") {
                this.openBigPopup(id);
            } else {
                this.$vs.notify({
                    title: 'Null Value',
                    text: 'Candidate ID Can not be null',
                    color: 'dark,'
                });
            }
        },
        openUpdateBatchPopup(e, cell) {
            let person = cell._cell.row.data
            let previous_batch_start_date = this.convertToDate(person.batch_start_date)
            this.batch_can_id = person.id;
            this.batch_popup_title = person.identity + '-' + person.person_name + '-' + person.course + '-' + previous_batch_start_date
            this.update_batch_popup = true;
        },
        updateBatchDate() {
            let obj = {
                enrollment_id: this.batch_can_id,
                batch_start_date: moment(this.batch_date).unix(),
            };
            console.log("batch obj", obj);
            axios
                .put(`${constants.SERVER_API}updateBatchStartDate`, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    if (response.data.status == "success") {
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "success",
                        });
                        this.$emit("data-transfer")
                    } else {
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "danger",
                        });
                    }
                    this.batch_date = '';
                })
                .catch((error) => {
                    this.handleError(error);
                    console.log(error);
                });
            this.update_batch_popup = false
        },
        convertToDate(timestamp) {
            return moment.unix(timestamp).format("DD-MMM-YYYY");
        },
        TransferEnrollment(e, cell) {
            let transfer_popup_data = cell._cell.row.data
            console.log("okkkk", transfer_popup_data);
            this.cities = [];
            this.value1 = "";
            this.cityselected = "";
            this.enrollmentData = transfer_popup_data;
            this.transfer_enrollment_popup = true;
            this.getSpoc();
            this.getSRSpocs();
        },
        transferEnrollmentToSPoc() {
            this.$vs.loading({
                type: "default",
            });
            let obj = {
                transfer_to_spoc_id: this.value1.id,
                enrollent_id: this.enrollmentData.id,
            };
            console.log("enrollmentData", obj);
            axios
                .post(`${constants.SERVER_API}transferEnrollment`, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    if (response.data.status === "success") {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "success",
                        });
                        this.$emit('data-transfer')
                        this.cityselected = "";
                        this.value1 = "";
                        this.transfer_enrollment_popup = false;
                    } else if (response.data.status === "error") {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "danger",
                        });
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        getSpoc() {
            let url = `${constants.SERVER_API}getSpocs`;
            axios
                .get(url, {
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                    console.log(response);
                    response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
                    this.spoc_names = response.data.spocs;
                    this.rawBdeSR.forEach((sr) => {
                        this.spoc_names.push(sr);
                    });
                    var unique = this.spoc_names
                        .map((name) => {
                            return {
                                count: 1,
                                name: name.city,
                            };
                        })
                        .reduce((a, b) => {
                            a[b.name] = (a[b.name] || 0) + b.count;
                            return a;
                        }, {});
                    for (var key in unique) {
                        if (unique.hasOwnProperty(key)) {
                            this.cities.push(key);
                        }
                    }
                    this.cities = this.sortArrayAlphabetically(this.cities);
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        getSRSpocs() {
            this.cities = [];
            let url = `${constants.SERVER_API}getSRSpocs`;
            axios
                .get(url, {
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                    this.rawBdeSR = response.data.spocs;
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        HideColumn() {
            console.log('object--', this.selected_column);
            this.showAllCols()
            if (this.selected_column.toString() == 'All' && !this.hide_all) {
                this.treeDataHide[0].children.forEach((item) => {
                    this.tabulator.hideColumn(item.id)
                })
                this.hide_all = true
            } else if (this.selected_column.length > 0) {
                this.selected_column.forEach((selected_item) => {
                    this.treeDataHide[0].children.forEach((item) => {
                        if (item.id == selected_item) {
                            this.tabulator.hideColumn(item.id)
                        }
                    })
                })
                this.hide_all = false
            } else {
                this.showAllCols()
                this.hide_all = false
            }
        },
        showAllCols() {
            this.treeDataHide[0].children.forEach((treeData) => {
                this.tabulator.showColumn(treeData.id)
            });
        },
        showTreeBox() {
            console.log("clicked");
            return this.treeDataHide;
        },
        removechip(chip) {
            if (this.selected_column.indexOf(chip) === -1) {
                this.selected_column = [];
                this.treeDataHide[0].children.forEach((child) => {
                    if (child.label !== chip) {
                        this.selected_column.push(child.label);
                    }
                });
            } else {
                this.selected_column.splice(
                    this.selected_column.indexOf(chip),
                    1
                );
            }
        },
        getChipData(chip) {
            var chip_val = chip
            if (chip_val.includes('.')) {
                chip_val = chip_val.substr(chip_val.indexOf('.') + 1);
            }
            if (chip_val.includes('_')) {
                chip_val = chip_val.replace(/_/g, " ");
            }
            return chip_val
        },
        downloadCvsTable() {
            this.$vs.loading({
                background: "#0044BA",
                color: "white",
                container: this.$refs.loadableButton.$el,
                scale: 0.45
            });
            this.$emit("download-enrollments-report")
        },
    },
};
</script>

<style>
.v-selectt>.vs__dropdown-toggle {
    border: 1px solid #0044BA
}

.visit_date_picker>div input {
    background: #ffffff;
    -webkit-padding-start: 9%;
    display: block;
    border-radius: 5px;
    min-height: 44.47px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    width: 100%;
    border: 1px solid #0044ba;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
    color: #000000 !important;
    border-radius: 10px;
}

.tabulator_card {
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.tabulator {
    background-color: #f2f2f2;
}

.tabulator .tabulator-header {
    background-color: #f2f2f2;
    /* padding-block: 5px; */
}

.tabulator .tabulator-row:nth-child(odd) {
    background-color: #f2f2f2;
    color: rgb(0, 0, 0) !important;
    /* padding-block: 5px; */
}

.tabulator .tabulator-row:nth-child(even) {
    background-color: #e4e1e1;
    color: rgb(0, 0, 0) !important;
    /* padding-block: 5px; */
}

.tabulator .tabulator-header .tabulator-col {
    text-align: center;
}

.tabulator-calcs-bottom .tabulator-cell {
    background-color: rgb(0, 0, 0);
    color: #f2f2f2 !important;
}

.filling {
    background-color: #FF4757 !important;
}

.a-iconns>.material-icons {
    margin-left: auto;
}

.a-icon {
    outline: none;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

i {
    font-size: 18px;
}

.a-iconns {
    color: #000;
    font-weight: 500;
    font-size: 14px;
    width: 99%;
    display: flex;
    justify-content: flex-end !important;
}

.visit-tree-drop>.vs-con-dropdown {
    width: 100%;
    min-height: 32px;
    padding-inline: 5px;
    color: #000000;
    display: flex !important;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #535252ce;
    border-radius: 10px;
    line-height: 35px;
}
</style>