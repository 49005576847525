<template>
  <div>
    <vs-table
      multiple
      v-model="selected"
      max-items="50"
      :data="$store.state.MwbLeads"
    >
      <template slot="thead">
        <vs-th> Can-ID</vs-th>
        <vs-th>Person Name</vs-th>
        <vs-th>Course</vs-th>
        <vs-th v-if="selected_courses.includes('CFA')">CFA Level</vs-th>
        <vs-th v-if="selected_courses.includes('FRM')">FRM Part</vs-th>
        <vs-th>Batch</vs-th>
        <vs-th>Batch Start Date</vs-th>
        <vs-th>City</vs-th>
        <vs-th>Agreed Cost</vs-th>
        <vs-th>Amount Paid</vs-th>
        <vs-th>Amount Pending</vs-th>
        <vs-th>Enrolled By</vs-th>
        <vs-th>Enrollment Date</vs-th>
        <vs-th>Agreement Status</vs-th>
        <vs-th>Going For Loan</vs-th>
        <vs-th>Tenure</vs-th>
        <vs-th>Email Sent Status</vs-th>
        <vs-th>Info</vs-th>
        <vs-th v-if="getUserId(132) || getUserId(39) || getUserId(937) || getUserId(100)">Transfer</vs-th>
        <vs-th
          v-if="
            getUserId(132) || getUserId(39) || getUserId(77) || getUserId(937) || getUserId(432) || checkRole('GM') || getUserId(1283)
          "
          >Update Batch Date</vs-th
        >
      </template>

      <template slot-scope="{ data }">
        <tbody ref="tbody">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <div>{{ tr.identity }}</div>
            </vs-td>
            <vs-td :data="tr.email">
              <vs-row>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-w="12"
                >
                  <vx-tooltip
                    :title="tr.spoc_name + ' (' + tr.engagement_added_on + ')'"
                    :text="tr.engagement_details"
                    >{{ tr.person_name }}</vx-tooltip
                  >
                </vs-col>
              </vs-row>
            </vs-td>

            <vs-td>
              <div>{{ tr.course }}</div>
            </vs-td>
            <vs-td v-if="selected_courses.includes('CFA')">
              <div>{{ tr.cfa_selected_level }}</div>
            </vs-td>
            <vs-td v-if="selected_courses.includes('FRM')">
              <div>{{ tr.frm_selected_part }}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.batch }}</div>
            </vs-td>

            <vs-td>
              <div>{{ convertToDate(tr.batch_start_date) }}</div>
            </vs-td>

            <vs-td>
              <div>{{ tr.city }}</div>
            </vs-td>

            <vs-td>
              <div>{{ tr.agreed_cost }}</div>
            </vs-td>

            <vs-td>
              <div>{{ tr.amount_paid + tr.amount_in_process }}</div>
            </vs-td>

            <vs-td>
              <div>{{ tr.agreed_cost - (tr.amount_paid + tr.amount_in_process) }}</div>
            </vs-td>

            <vs-td>
              <div>{{ tr.assigned_to_name }}</div>
            </vs-td>

            <vs-td>
              <div>{{ unixTimestampConvert(tr.enrollment_date) }}</div>
            </vs-td>

            <vs-td>
              <div>{{ getAgreementStatus(tr.agreed_status) }}</div>
            </vs-td>

            <vs-td>
              <div>{{ getLoanStatus(tr.enrollment_loan_status) }}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.tenure }}</div>
            </vs-td>
            <vs-td>
              <div>{{ getEmailStatus(tr.agreement) }}</div>
            </vs-td>

            <vs-td>
              <vs-row>
                <!-- <img
                  @click="openPopupEmit(tr)"
                  src="https://img.icons8.com/material-outlined/24/000000/info.png"
                />-->
                <div @click="openPopupEmit(tr)">
                  <vs-icon
                    icon="info_outline"
                    size="small"
                    color="dark"
                  ></vs-icon>
                </div>
                <div v-if="tr.agreed_status === 0">
                  <vs-icon
                    icon="highlight_off"
                    size="small"
                    color="danger"
                  ></vs-icon>
                </div>
                <div v-if="tr.agreed_status === 1">
                  <vs-icon
                    icon="check_circle_outline"
                    size="small"
                    color="success"
                  ></vs-icon>
                </div>
                <div @click="Resendmail(tr)" v-if="tr.agreement !== null">
                  <img
                    src="https://img.icons8.com/android/24/000000/forward-arrow.png"
                  />
                </div>
                <!-- <img
                
                  src="https://img.icons8.com/color/24/000000/error.png"
                />-->

                <!-- <svg
                  v-if="tr.agreed_status === 1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="24"
                  height="24"
                  viewBox="0 0 172 172"
                  style=" fill:#000000;"
                >
                  <g
                    fill="none"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0,172v-172h172v172z" fill="none" />
                    <g fill="#2ecc71">
                      <path
                        d="M35.83333,21.5c-7.90483,0 -14.33333,6.4285 -14.33333,14.33333v100.33333c0,7.90483 6.4285,14.33333 14.33333,14.33333h100.33333c7.90483,0 14.33333,-6.4285 14.33333,-14.33333v-69.93099l-14.33333,14.33334l0.014,55.59765h-100.34733v-100.33333h84.26432l14.33333,-14.33333zM152.59961,23.59961l-73.76628,73.76628l-23.59961,-23.59961l-10.13411,10.13411l33.73372,33.73372l83.90039,-83.90039z"
                      />
                    </g>
                  </g>
                </svg>-->
              </vs-row>
            </vs-td>
            <vs-td v-if="getUserId(132) || getUserId(39) || getUserId(937) || getUserId(100)">
              <vs-row>
                <div @click="TransferEnrollment(tr)">
                  <!-- <img src="https://img.icons8.com/doodle/24/000000/circled-right-2.png"/> -->
                  <img
                    src="https://img.icons8.com/ios-filled/24/000000/shortcut.png"
                  />
                </div>
              </vs-row>
            </vs-td>

            <vs-td
              v-if="
                getUserId(132) ||
                getUserId(39) ||
                getUserId(77) ||
                getUserId(937) ||
                getUserId(432) ||
                checkRole('GM') ||
                 getUserId(1283)
              "
            >
              <vs-button
                color="dark"
                type="flat"
                icon="update"
                size="large"
                @click="openUpdateBatchPopup(tr)"
              ></vs-button>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-popup
      :id="'assignlead'"
      :title="'Transfer Enrollments'"
      :active.sync="transfer_enrollment_popup"
    >
      <vs-row style="margin-top: 5%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <b style="color: gray">City</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
          vs-w="10"
        >
          <v-select
            :options="cities"
            v-model="cityselected"
            style="width: 450px; position: fixed; z-index: 1000"
          />
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 5%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <b style="color: gray">SPOC</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
          vs-w="10"
        >
          <v-select
            label="full_name"
            :multiple="false"
            :options="cityspoc"
            v-model="value1"
            style="width: 450px; position: fixed; z-index: 900"
          />
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 3%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-start"
          vs-w="12"
        >
          <vs-button
            color="dark"
            type="filled"
            @click="transferEnrollmentToSPoc()"
            >Assign</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
    <vs-popup :title="`${batch_popup_title}`" :active.sync="update_batch_popup">
      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="8"
        >
          Batch Date :
          <datepicker
            placeholder="Select Batch Date"
            v-model="batch_date"
            style="position: fixed; width: 350px"
          ></datepicker>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
          <vs-button
            color="dark"
            type="filled"
            size="small"
            @click="updateBatchDate"
            >Update Batch Date</vs-button
          >
        </vs-col>
      </vs-row>
      <br />
    </vs-popup>
  </div>
</template>

<script>
import Vue from "vue";
import EventBus from "../eventbus.js";
import axios from "axios";
import moment from "moment";
import enrollentbutton from "./buttons/AddEnrollmentButton.vue";
import constants from "../../../constants.json";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
Vue.config.productionTip = false;
export default {
  components: {
    enrollentbutton,
    vSelect,
    Datepicker,
  },
  props: ["totalpagination", "selected_courses"],
  data() {
    return {
      batch_date: "",
      transfer_enrollment_popup: false,
      update_batch_popup: false,
      selectall: false,
      expand: false,
      selectedleads: [],
      notselectedleads: [],
      tablelinks: 0,
      currentTablePage: 1,
      selected: [],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot",
      ],
      sent_status: "",
      //transfer Enrollment
      decidebool: false,
      cityspoc: [],
      title: "Example",
      spoc_names: [],
      totalleadcount: "",
      value1: "",
      cities: [],
      cityselected: "",
      deselected: "",
      allselect: "",
      sendobj: "",
      search: "",
      rawBdeSR: [],
      team: null,
      assignLeadPopupHeading: null,
      enrollmentData: "",
      batch_can_id: null,
      batch_popup_title : ''
    };
  },
  mounted() {
    this.cities = [];
    this.getAllSpoc();
    this.getEnrollments();
    document
      .getElementsByClassName("vs-table--thead")[0]
      .addEventListener("click", this.selectallFunc, false);
    EventBus.$on("open-email-bdeleads", () => {
      if (this.selectall === false) {
        EventBus.$emit("open-email-engagement", this.selected);
      } else if (this.selectall == true) {
        EventBus.$emit("open-email-leadsdatatable", this.notselectedleads);
      }
    });
  },
  watch: {
    selected: function (value) {
      this.notselectedleads = [];
      this.$store.state.MwbLeads.forEach((lead) => {
        if (value.indexOf(lead) === -1) {
          this.notselectedleads.push(lead);
        }
      });
      // console.log(value)
    },
    currentTablePage: function (val) {
      this.getMwbLeads(val);
    },
    cityselected(value) {
      this.filterspoc(value);
    },
    value1(value) {
      console.log("value", value);
    },
  },
  methods: {
    getLoanStatus(loan_status){
      if(loan_status == 1){
        return "Yes";
      }
      if(loan_status == 0){
        return "No";
      }
    },
    convertToDate(timestamp) {
      return moment.unix(timestamp).format("DD-MMM-YYYY");
    },
    openUpdateBatchPopup(person) {
      console.log("person", person);
      let previous_batch_start_date = this.convertToDate(person.batch_start_date)
      this.batch_can_id = person.id;
      this.batch_popup_title = person.identity + '-' +person.person_name + '-' + person.course + '-' + previous_batch_start_date
      this.update_batch_popup = true;
    },
    updateBatchDate() {
      let obj = {
        enrollment_id: this.batch_can_id,
        batch_start_date: moment(this.batch_date).unix(),
      };
      console.log("batch obj", obj);
      axios
        .put(`${constants.SERVER_API}updateBatchStartDate`, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.$vs.notify({
              title: response.data.message,
              text: "",
              color: "success",
            });
            this.getEnrollments();
          } else {
            this.$vs.notify({
              title: response.data.message,
              text: "",
              color: "danger",
            });
          }
          this.batch_date = '';
        })
        .catch((error) => {
          this.handleError(error);
          console.log(error);
        });
        this.update_batch_popup = false
    },
    checkRole(team) {
      if (localStorage.getItem("role") == 'cm4' && localStorage.getItem("team") == team) {
        return true;
      } else {
        return false;
      }
    },
    getAllSpoc() {
      let url = null;
      if (this.getrole("exed2")) {
        url = `${constants.SERVER_API}getExed`;
      } else {
        url = `${constants.SERVER_API}getSpocs`;
      }
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.logged_in_user_id = response.data.logged_in_user_id;
          // this.registeredSpoc = response.data.logged_in_user_id;
          // response.data.spocs.forEach((spoc) => {
          //     if (spoc.id === this.registeredSpoc) {
          //         // console.log(spoc, "found the spoc");
          //         this.identityCityCode = spoc.city.charAt(0);
          //         // console.log(this.identityCityCode);
          //     }
          // });
          // this.extractSpocByCity(response.data.spocs);
          // this.getReceipts();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    filterspoc(val) {
      this.value1 = "";
      this.cityspoc = [];
      console.log(val);
      this.spoc_names.forEach((spoc) => {
        // console.log(spoc)
        if (spoc.city === val) {
          this.cityspoc.push(spoc);
          // console.log("spoc name", spoc.full_name);
        }
      });
    },
    getEnrollments(page) {
      this.$vs.loading();
      let params = {
        page: page,
        can_id: this.enrollmentData.identity,
        // spoc_ids: this.retrieveSelectedSpoc(),
        // course: this.courses.join(),
        batch: this.batch,
        // name: this.value1,
        designation: this.designation_search,
        // agreement_status: this.getAgreementStatus(this.agreement_status),
        email_status: this.email_status,
        last_call_start_date: "",
        last_call_end_date: "",
        enrollment_start_date: "",
        enrollment_end_date: "",
        city: this.SelectCity,
      };

      // if (this.dateRange.startDate !== "" && this.dateRange.endDate !== "") {
      //   params.enrollment_start_date = this.datatoTimestamp(this.dateRange.startDate);
      //   params.enrollment_end_date = this.datatoTimestamp(this.dateRange.endDate);
      // }

      // if (
      //   this.enrollment_date.startDate !== "" &&
      //   this.enrollment_date.endDate !== ""
      // ) {
      //   params.enrollment_start_date = this.datatoTimestamp(
      //     this.enrollment_date.startDate
      //   );
      //   params.enrollment_end_date = this.datatoTimestamp(
      //     this.enrollment_date.endDate
      //   );
      // }

      console.log(params);
      // return;
      axios
        .get(`${constants.SERVER_API}getEnrollments`, {
          params: params,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === "error") {
            this.handleNotification(response);
          } else {
            this.countsleads = response.data.enrollments.total;
            response.data.enrollments.data.forEach((lead) => {
              lead.engagements = [];
              lead.mobile = [];
              lead.emails = [];
            });
            this.currentTablePage = response.data.enrollments.current_page;
            this.tablelinks = response.data.enrollments.last_page;
            this.$store.commit(
              "MOUNT_MWB_LEADS",
              response.data.enrollments.data
            );
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    TransferEnrollment(tr) {
      this.cities = [];
      this.value1 = "";
      this.cityselected = "";
      this.enrollmentData = tr;
      this.transfer_enrollment_popup = true;
      this.getSpoc();
      this.getSRSpocs();
    },
    getSRSpocs() {
      this.cities = [];
      let url = `${constants.SERVER_API}getSRSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.rawBdeSR = response.data.spocs;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpoc() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
          this.spoc_names = response.data.spocs;
          this.rawBdeSR.forEach((sr) => {
            this.spoc_names.push(sr);
          });
          var unique = this.spoc_names
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.cities.push(key);
            }
          }
          this.cities = this.sortArrayAlphabetically(this.cities);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getUserId(userID) {
      if (this.logged_in_user_id === userID) {
        return true;
      } else {
        return false;
      }
    },
    transferEnrollmentToSPoc() {
      this.$vs.loading({
        type: "default",
      });
      let obj = {
        transfer_to_spoc_id: this.value1.id,
        enrollent_id: this.enrollmentData.id,
      };
      console.log("enrollmentData", obj);
      axios
        .post(`${constants.SERVER_API}transferEnrollment`, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            this.$vs.loading.close();
            this.$vs.notify({
              title: response.data.message,
              text: "",
              color: "success",
            });
            this.getEnrollments();
            this.cityselected = "";
            this.value1 = "";
            this.transfer_enrollment_popup = false;
          } else if (response.data.status === "error") {
            this.$vs.loading.close();
            this.$vs.notify({
              title: response.data.message,
              text: "",
              color: "danger",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    Resendmail(tr) {
      EventBus.$emit("open-resend-email", tr);
    },
    getEmailStatus(agreement) {
      // console.log("agree", agreement);
      let returnValue = "";
      if (agreement !== null) {
        switch (agreement.sent_status) {
          case 0:
            returnValue = "Not Sent";
            break;
          case 1:
            returnValue = "Sent";
            break;
        }
        return returnValue;
      } else {
        return "old Data";
      }
    },
    getAgreementStatus(agreed_status) {
      switch (agreed_status) {
        case 0:
          return "Incomplete";
        case 1:
          return "Complete";
      }
    },
    getMode(mode) {
      switch (mode) {
        case "study_material_training":
          return "SM + T";
        case "study_material_only":
          return "SMO";
        case "only_license":
          return "OL";
      }
    },
    getSubject(subjects) {
      let arr = subjects.split(",");
      let newarr = [];
      arr.forEach((value) => {
        if (value !== "E&L") {
          newarr.push(value.charAt(0));
        } else {
          newarr.push(value);
        }
      });
      return newarr.join();
    },
    getImageSource(approved) {
      switch (approved) {
        case "":
          return "https://img.icons8.com/color/24/000000/error.png";
        case "Yes":
          return;
        case "No":
          return;
      }
    },
    selectallFunc() {
      this.selectall = !this.selectall;
    },
    openEnrollmentApproval(payload) {
      EventBus.$emit("openEmrollmentApprovalPopup", payload);
    },
    getMHPIndication(next_call) {
      // console.log(next_call);
      let today = new Date().setHours(0, 0, 0, 0);
      today = today.valueOf();
      let next_call_date = moment.unix(next_call).format("YYYY-MM-DD");
      today = moment.unix(today / 1000).format("YYYY-MM-DD");
      let comaprision = moment(next_call_date).isBefore(today);
      let same = moment(next_call_date).isSame(today);
      // console.log(next_call_date, today);
      // console.log(comaprision);
      if (comaprision || same) {
        let string =
          "https://img.icons8.com/color/24/000000/warning-shield.png";
        return string;
      } else {
        return "";
      }
    },
    unixTimestampConvert(value) {
      var dateString = moment.unix(value).format("DD-MMM-YYYY");
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return dateString;
      }
    },
    additional(emit, data) {
      this.openPopupEmit(data, emit);
      // EventBus.$emit("select-call-section");
    },
    openPopupEmit(payload) {
      console.log(payload);
      this.openBigPopup(payload.mwb_id);
      // this.$vs.loading();
      // axios
      //   .get(`${constants.SERVER_API}getMwbLead?mwb_id=${payload.mwb_id}`, {
      //     headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
      //   })
      //   .then(response => {
      //     response.data.data.engagements = [];
      //     response.data.data.mobile = [];
      //     response.data.data.emails = [];
      //     this.$store.commit("PUSH_TO_WAITING", [response.data.data]);
      //     axios
      //       .get(
      //         `${constants.SERVER_API}getSingleMwbLead?can_id=${response.data.data.can_id}&person_id=${response.data.data.person_id}`,
      //         {
      //           headers: {
      //             Authorization: `Bearer ${localStorage.userAccessToken}`
      //           }
      //         }
      //       )
      //       .then(response2 => {
      //         this.$store.commit("UPDATE_NEW_DATA", [
      //           response.data.data,
      //           response2.data.engagements,
      //           response2.data.mobile,
      //           response2.data.emails
      //         ]);
      //         EventBus.$emit("open-student-details-popup", response.data.data);
      //         this.$vs.loading.close();
      //       })
      //       .catch(error => {
      //         this.handleError(error);
      //       });
      //   })
      //   .catch(error => {
      //     this.handleError(error);
      //   });
      // this.$vs.loading({
      //   type: "corners"
      // });
      // let url = `${constants.SERVER_API}getSingleMwbLead?can_id=${payload.can_id}&person_id=${payload.person_id}`;
      // axios
      //   .get(url, {
      //     headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
      //   })
      //   .then(response => {
      //     this.$store.commit("UPDATE_NEW_DATA", [
      //       payload,
      //       response.data.engagements,
      //       response.data.mobile,
      //       response.data.emails
      //     ]);
      //     EventBus.$emit("open-student-details-popup", payload);
      //     if (emit !== undefined) {
      //       EventBus.$emit(emit);
      //     }
      //     this.$vs.loading.close();
      //   })
      //   .catch(error => {
      //     this.handleError(error);
      //   });
    },
    addengagement(payload) {
      console.log("emitting");
      EventBus.$emit("open-enrollment-popup", payload);
    },
    fetchbdeName(bdeid) {
      let name = "";
      this.$store.state.bdes.forEach((bde) => {
        if (bde.id === bdeid) {
          name = bde.bdename;
        }
      });
      return name;
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    fetchEngagementDate(latest_engagement) {
      let date = "";
      this.$store.state.engagements.forEach((engagement) => {
        if (engagement.engagement === latest_engagement) {
          date = engagement.engagement_date;
        }
      });
      return date;
    },
    getColor(level) {
      switch (level) {
        case "M1":
          return "danger";
        case "M2":
          return "danger";
        case "M3":
          return "success";
        case "M3+":
          return "success";
        case "M3++":
          return "success";
        case "M4-":
          return "danger";
        case "M4":
          return "danger";
        case "M5":
          return "success";
        case "M6":
          return "success";
        case "L1":
          return "danger";
        case "L2":
          return "warning";
        case "L3":
          return "warning";
        case "L3+":
          return "success";
        case "L4-":
          return "danger";
        case "L4":
          return "warning";
        case "L5":
          return "success";
        case "L6":
          return "success";
        default:
          return "primary";
      }
    },
    engagementlast(engagements) {
      return engagements.slice(-1)[0];
    },
  },
};
</script>

<style>
.datepicker .vdp-datepicker__calendar {
  z-index: 2000;
}
</style>
